import React from 'react'
import { Helmet } from 'react-helmet'
import ErrorPanel from '../ErrorPanel'

const ErrorPage = () => (
  <>
    <Helmet title="Server error" />
    <ErrorPanel
      data-testid="error-page"
      title="500"
      description="Something went wrong. Please try refreshing this page."
    />
  </>
)

export default ErrorPage
