import { RefObject, useEffect } from 'react'

type ElType = RefObject<any>

const refCheck = (ref: ElType, event: MouseEvent) => {
  return !ref.current || ref.current.contains(event.target as Node)
}

function useOnOutsideClick(
  ref: ElType | ElType[],
  handler: (event: MouseEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        Array.isArray(ref)
          ? ref.some(r => refCheck(r, event))
          : refCheck(ref, event)
      ) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler])
}

export default useOnOutsideClick
