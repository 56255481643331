import 'core-js/modules/es.string.includes'
import 'core-js/modules/es.array.includes'
import 'react-app-polyfill/ie11'
import 'polyfill-object.fromentries'
import { datadogRum } from '@datadog/browser-rum'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import packageJson from '../package.json'
import { AppProviders } from './components/AppProviders'
import App from './pages/App'
import { nonNullable } from './utils/array'

// Declaring the dataLayer global variable for Google Tag Manager
declare global {
  interface Window {
    dataLayer: any[]
  }
}

if (
  typeof window !== 'undefined' &&
  window.location.href.includes('localhost') === false
) {
  const hostname = window.location.hostname
  const href = window.location.href
  const environment = href.includes('dev.')
    ? 'dev'
    : href.includes('qa.')
    ? 'qa'
    : href.includes('uat.')
    ? 'uat'
    : href.includes('toblerones.')
    ? 'toblerones'
    : href.includes('thesharkeys.')
    ? 'thesharkeys'
    : href.includes('jatz.')
    ? 'jatz'
    : href.includes('koalas.')
    ? 'koalas'
    : href.includes('wizards.')
    ? 'wizards'
    : href.includes('perftest.')
    ? 'perftest'
    : href.includes('pentest.')
    ? 'pentest'
    : href.includes('sit.')
    ? 'sit'
    : 'production'

  if (environment === 'production') {
    // GTM workspace - "admin.playhq.com"
    TagManager.initialize({
      gtmId: 'GTM-KBHCLBW',
    })
  } else {
    // GTM workspace - "admin.playhq.com - NON_PROD"
    TagManager.initialize({
      gtmId: 'GTM-KKBCNCW',
    })
  }

  const instrumentations = [...getWebInstrumentations()]

  if (process.env.REACT_APP_GRAFANA_FARO_TRACING_ENABLED === 'true') {
    const endpoints: string[] = [
      process.env.REACT_APP_GRAPH_ENDPOINT,
      process.env.REACT_APP_SPECTATOR_ENDPOINT,
      process.env.REACT_APP_SPECTATOR_WS_ENDPOINT,
    ].filter(nonNullable)

    instrumentations.push(
      new TracingInstrumentation({
        instrumentationOptions: {
          propagateTraceHeaderCorsUrls: endpoints,
        },
      }),
    )
  }

  if (process.env.REACT_APP_GRAFANA_FARO_URL) {
    initializeFaro({
      url: process.env.REACT_APP_GRAFANA_FARO_URL,
      app: {
        name: 'fastbreak',
        environment,
        release: process.env.CODEBUILD_RESOLVED_SOURCE_VERSION,
      },
      instrumentations: instrumentations,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'webkit-masked-url:',
      ],
    })
  }

  // DataDog is still used in production, so keeping it here will allow faro to
  // cutover when the env variable is set.
  if (
    !process.env.REACT_APP_GRAFANA_FARO_URL &&
    process.env.REACT_APP_DATADOG_APPLICATION_ID &&
    process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      allowedTracingOrigins: [
        process.env.REACT_APP_GRAPH_ENDPOINT!.replace('/graphql', ''),
        process.env.REACT_APP_SPECTATOR_ENDPOINT!.replace('/graphql', ''),
      ],
      service: `fastbreak-${process.env.REACT_APP_TENANT_SLUG}`,
      env:
        hostname.match(/^([\w]+)\.([\w]+)\.playhq\.com$/)?.[2] ?? 'production',
      version: packageJson.version,
      premiumSampleRate: 0,
      replaySampleRate: 0,
      sampleRate: 10,
      tracingSampleRate: 10,
      trackInteractions: true,
    })
  }
}

export const bootstrap = async (
  component: React.ReactElement<any>,
  elId = 'root',
) => {
  ReactDOM.render(
    <React.StrictMode>
      <AppProviders>{component}</AppProviders>
    </React.StrictMode>,
    document.getElementById(elId),
  )
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCKS === 'true'
) {
  import('./mocks/dev-server').then(({ server }) =>
    server
      .start({
        onUnhandledRequest: 'bypass',
      })
      .then(() => bootstrap(<App />)),
  )
} else if (process.env.NODE_ENV !== 'test') {
  bootstrap(<App />)
}

// We don't want to unregister the service worker in development as it can terminate MSW
if (process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}
