import Icon from '@gameonsports/components/lib/Icon'
import { Text } from '@gameonsports/components/lib/TextV3'
import { Link } from '@reach/router'
import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import AccountContext from '../../context/AccountContext'
import useToggle from '../../hooks/useToggle'
import theme from '../../theme'
import { media } from '../../utils/styled-components-utils'
import { AppcuesLaunchpad } from '../AppcuesLaunchpad/AppcuesLaunchpad'

interface NavigationFooterProps {
  isExpanded?: boolean
  onClickAccount?: () => void
}

const Container = styled.div`
  position: relative;
  align-self: flex-end;
`

const Circle = styled(Text)<{
  backgroundColor: keyof typeof theme
}>`
  display: grid;
  align-self: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props => props.theme[props.backgroundColor]};
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  font-weight: 700;
`

const FooterIcon = styled(Circle)`
  background: none;

  /* stylelint-disable-next-line selector-max-compound-selectors */
  & > div > div > button {
    color: ${props => props.theme.grey400};
  }
`

const FooterItem = styled.button<NavigationFooterProps>`
  width: 100%;
  border: none;
  background-color: ${props => props.theme.black400};
  color: ${props => props.theme.grey400};
  transition: background-color 150ms ease;
  display: grid;
  grid-column-gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  word-break: break-word;
  text-align: left;
  text-decoration: none;
  outline: none;

  > :not(${Circle}) {
    display: none;
  }

  ${media.desktop`
    padding: 1rem;
    grid-template-columns: auto 1fr 1rem;
    justify-content: stretch;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${props => props.theme.black400};
    }

    > :not(${Circle}) {
      display: block;
    }
  `}

  ${props =>
    props.isExpanded &&
    css`
      padding: 1rem;
      grid-template-columns: auto 1fr 1rem;
      justify-content: stretch;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${props.theme.black400};
      }

      > :not(${Circle}) {
        display: block;
      }
    `}

  & + & {
    border-top: solid 1px ${props => props.theme.secondary};
  }
`

const AccountDetails = styled.div<NavigationFooterProps>`
  && {
    display: none;
    grid-gap: 0.25rem;

    ${media.desktop`
      display: grid;
    `}

    ${props =>
      props.isExpanded &&
      css`
        display: grid;
      `}
  }
`

const StyledIcon = styled(Icon)<NavigationFooterProps>`
  align-self: center;
  justify-self: center;
`

const Nav = styled.nav<{ isExpanded: boolean }>`
  max-height: 0;
  transition: max-height 200ms ease-in-out;

  ${props =>
    props.isExpanded &&
    css`
      max-height: 7rem;
    `}
`

const StyledCircle = styled(Circle)`
  cursor: pointer;
`

const AccountMenuList = styled.ul<NavigationFooterProps>`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;

  display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
`

const AccountMenuLink = styled(Link)`
  display: block;
  padding: 1rem 1.5rem;
  background-color: ${props => props.theme.black400};
  border-top: solid 1px ${props => props.theme.secondary};
  color: ${props => props.theme.white400};
  text-decoration: none;
  transition: background-color 150ms ease;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.black400};
  }
`

const NavigationFooter: React.FunctionComponent<NavigationFooterProps> = ({
  onClickAccount,
  isExpanded,
}) => {
  const { user } = useContext(AccountContext)
  const [showNav, toggleNav] = useToggle()
  const appcuesElementID = 'appcues-launchpad'

  useEffect(() => {
    // If the parent nav closes and the account nav is open, close it
    if (!isExpanded && showNav) {
      toggleNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded])

  return user ? (
    <Container>
      <FooterItem
        as="a"
        isExpanded={isExpanded}
        onClick={() => {
          document
            .getElementById(appcuesElementID)
            ?.querySelector('button')
            ?.click()
        }}
      >
        <FooterIcon
          weight="700"
          size="22"
          backgroundColor="black400"
          color="grey400"
        >
          <AppcuesLaunchpad elementID={appcuesElementID} />
        </FooterIcon>
        <Text size="14">Updates</Text>
        <StyledIcon
          name="copy"
          color="grey400"
          size="12"
          isExpanded={isExpanded}
        />
      </FooterItem>
      <FooterItem
        as="a"
        isExpanded={isExpanded}
        href="https://support.playhq.com/"
        target="_blank"
        rel="nofollow noopener"
      >
        <Circle
          weight="700"
          backgroundColor="grey400"
          color="black400"
          size="22"
        >
          ?
        </Circle>
        <Text size="14">Support</Text>
        <StyledIcon
          name="share"
          color="grey400"
          size="12"
          isExpanded={isExpanded}
        />
      </FooterItem>
      <FooterItem
        isExpanded={isExpanded}
        onClick={() => {
          onClickAccount && onClickAccount()
          toggleNav() // toggles the account sub navigation
        }}
        data-testid="account-button"
      >
        <StyledCircle
          weight="700"
          textTransform="uppercase"
          backgroundColor="secondary"
        >
          {user.profile ? user.profile.firstName.charAt(0) : 'U'}
        </StyledCircle>
        <AccountDetails isExpanded={isExpanded}>
          <Text color="grey400" size="14" weight="700">
            {user.profile
              ? `${user.profile.firstName} ${user.profile.lastName}`
              : '-'}
          </Text>
          <Text color="grey400" size="12">
            {user.profile ? user.profile.email : '-'}
          </Text>
        </AccountDetails>
        <StyledIcon
          name={showNav ? 'up-arrow' : 'down-arrow'}
          color="grey400"
          size="8"
          isExpanded={isExpanded}
        />
      </FooterItem>
      <Nav isExpanded={showNav}>
        <AccountMenuList isExpanded={isExpanded}>
          <li>
            <AccountMenuLink to="/account" data-testid="account-link">
              Account
            </AccountMenuLink>
          </li>
          <li>
            <AccountMenuLink to="/auth/logout" data-testid="logout-link">
              Logout
            </AccountMenuLink>
          </li>
        </AccountMenuList>
      </Nav>
    </Container>
  ) : null
}

export default NavigationFooter
