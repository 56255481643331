import Icon, { IconName } from '@gameonsports/components/lib/Icon'
import Pill from '@gameonsports/components/lib/Pill'
import { Stack } from '@gameonsports/components/lib/StackV2'
import { Text } from '@gameonsports/components/lib/TextV3'
import Component from '@reach/component-component'
import { Link, LinkGetProps, LinkProps, Match } from '@reach/router'
import React from 'react'
import styled, { css } from 'styled-components'
import NavigationContext from '../../context/NavigationContext'
import useToggle from '../../hooks/useToggle'
import { getProps } from '../../utils/route'
import { media } from '../../utils/styled-components-utils'
import { removeSpaces } from '../../utils/text'

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  grid-column: 1 / -1;
`

const ListItem = styled.li<{ isOpen?: boolean }>`
  transition: background-color 150ms ease;
  display: block;

  li {
    display: block;
  }

  ${props =>
    props.isOpen &&
    css`
      background-color: ${props.theme.black400};
      border-bottom: 1px solid ${props.theme.secondary};
    `};
`

const ParentListItem = styled.div`
  position: relative;

  svg {
    fill: ${props => props.theme.grey400};
  }
`

const NavItemContainer = styled.div`
  color: ${props => props.theme.grey400};
  font-size: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1.5rem 1fr;
  grid-column-gap: 0.75rem;
  justify-content: start;
  justify-items: start;
  align-items: center;
  transition: color 150ms ease;
`

const ToggleButton = styled.span`
  display: block;
  padding: 0;
  margin-left: auto;
`

type NavLinkProps = LinkProps<{}> & { to?: string }

const NavLink = styled(Link)<NavLinkProps>`
  display: block;
  padding: 1rem;
  width: 100%;
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: background-color 150ms ease;

  span {
    transition: color 150ms ease;
  }

  svg {
    fill: ${props => props.theme.grey400};
    transition: fill 150ms ease;
    margin-left: 0.2rem;

    ${media.desktop`
      margin-left: 0;
    `}
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.darkGrey400};
    color: ${props => props.theme.white400};

    span {
      color: ${props => props.theme.white400};
    }
    svg {
      fill: ${props => props.theme.white400};
    }
  }

  &[aria-current] {
    span {
      color: ${props => props.theme.primary};
    }

    svg {
      fill: ${props => props.theme.primary};
    }

    &:hover,
    &:focus {
      background-color: ${props => props.theme.darkGrey400};

      span {
        color: ${props => props.theme.primary};
      }
      svg {
        fill: ${props => props.theme.primary};
      }
    }
  }
`

const NavItemTitle = styled.span<{ navExpanded: boolean }>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  display: none;

  ${media.desktop`
    display: block;
  `}

  ${props => props.navExpanded && `display: block;`}
`

const SubNavIcon = styled(Icon)`
  justify-self: center;
`

const SubNavLink = styled(NavLink)`
  padding: 0.75rem 1rem 0.75rem 3rem;
`

const StyledPill = styled(Pill)`
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  /* stylelint-disable-next-line declaration-no-important */
  color: ${props => props.theme.white400} !important;
  margin-left: auto;
`

const StyledText = styled(Text)`
  white-space: normal;
`

interface NavItemProps {
  label: string
  to?: string
  icon?: IconName
  onClick?: () => void
  getPropsOverride?: ((props: LinkGetProps) => {}) | undefined
  showNewPill?: boolean
}

const NavItemLink: React.FC<NavItemProps> = ({
  label,
  to,
  icon,
  children,
  onClick,
  getPropsOverride,
  showNewPill,
}) => (
  <NavLink
    as={to ? Link : 'button'}
    getProps={getPropsOverride || getProps}
    to={to || ''}
    onClick={onClick}
    data-testid={`${removeSpaces(label.toLowerCase())}-nav-link`}
  >
    <NavItemContainer>
      <Icon name={icon || 'default'} size="24" />
      <NavigationContext.Consumer>
        {({ navExpanded }) => (
          <NavItemTitle navExpanded={navExpanded}>
            <Stack direction="row" alignItems="center">
              <StyledText>{label}</StyledText>
              {showNewPill && (
                <StyledPill text="NEW" background="blueberry400" />
              )}
            </Stack>
          </NavItemTitle>
        )}
      </NavigationContext.Consumer>
      {children}
    </NavItemContainer>
  </NavLink>
)

export const SubNavItem: React.FC<NavItemProps> = ({ label, to, icon }) => (
  <li>
    <SubNavLink
      getProps={getProps}
      to={to || ''}
      data-testid={`${removeSpaces(label.toLowerCase())}-nav-link`}
    >
      <NavItemContainer>
        <SubNavIcon name={icon || 'default'} size="24" />
        <NavigationContext.Consumer>
          {({ navExpanded }) => (
            <NavItemTitle navExpanded={navExpanded}>{label}</NavItemTitle>
          )}
        </NavigationContext.Consumer>
      </NavItemContainer>
    </SubNavLink>
  </li>
)

const NavItem: React.FC<NavItemProps> = ({
  label,
  to,
  icon,
  children,
  getPropsOverride,
  showNewPill,
  onClick,
}) => {
  const testLabel = removeSpaces(label.toLowerCase())
  const isExpandable = !!children
  const pathsToMatch =
    (children &&
      React.Children.map(children, child => (child as any).props.to)) ||
    []

  const [on, toggle] = useToggle()

  return isExpandable ? (
    <ListItem isOpen={on}>
      <ParentListItem data-testid={`${testLabel}-item`}>
        <NavItemLink
          label={label}
          onClick={() => {
            onClick?.()
            toggle()
          }}
          icon={icon}
          getPropsOverride={getPropsOverride}
          aria-label={`Open or close ${testLabel} sub navigation`}
          showNewPill={showNewPill}
        >
          <ToggleButton>
            {on ? (
              <Icon name="minus" size="8" />
            ) : (
              <Icon name="plus" size="8" />
            )}
          </ToggleButton>
        </NavItemLink>
      </ParentListItem>

      {on && <NavList>{children}</NavList>}

      {pathsToMatch.map(path => (
        <Match path={path} key={`${path}-opener`}>
          {({ match }) => (
            <Component
              didMount={() => {
                if (match && !on) {
                  toggle()
                }
              }}
              didUpdate={() => {
                if (match && !on) {
                  toggle()
                }
              }}
            >
              {() => null}
            </Component>
          )}
        </Match>
      ))}
    </ListItem>
  ) : (
    <ListItem>
      <NavItemLink
        label={label}
        to={to}
        icon={icon}
        getPropsOverride={getPropsOverride}
        showNewPill={showNewPill}
        onClick={onClick}
      />
    </ListItem>
  )
}

export default NavItem
