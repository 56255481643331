import { toTitleCase } from './text'

// URL '*.playhq.com' indicates running in production
export const getIsProduction = () =>
  window.location.hostname.split('.')[1] === 'playhq'

export const getEnvironment = () => {
  const environment = window.location.hostname.split('.')[1]

  // When running locally there will be no environment, only localhost.
  if (!environment) {
    return 'Local'
  }

  // QA and UAT environemnts are abbreviations so should use upper case.
  if (environment === 'qa' || environment === 'uat') {
    return environment.toUpperCase()
  }

  // The sharkeys environment is 'thesharkeys', ie. 'bv.thesharkeys.playhq.com',
  // we need to remove 'the' prefix here, as none of the other squads have this.
  if (environment === 'thesharkeys') {
    return toTitleCase(environment.slice(3))
  }

  // Handle all other environments, jatz, toblerones, wizards etc.
  return toTitleCase(environment)
}

/**
 * @returns environment label based on URL
 *
 * localhost - `Local` eg. 'Local'
 * squad environment - `{TENANT} {Squad}` eg. 'AFL Sharkeys', 'BV Jatz'
 * staging environment - `{TENANT} {ENV}` eg. 'AFL QA', 'BV UAT'
 * production - not applicable
 */
export const getEnvironmentLabel = () => {
  const tenant = window.location.hostname.split('.')[0]

  if (tenant === 'localhost') {
    return 'Local'
  }

  return `${tenant.toUpperCase() ?? '-'} ${getEnvironment()}`
}
