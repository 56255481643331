import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import Auth from '@aws-amplify/auth'
import { datadogRum } from '@datadog/browser-rum'
import { navigate } from '@reach/router'
import { createClient } from 'graphql-ws'
import merge from 'lodash/merge'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { LOCAL_STORAGE_KEY } from '../../constants/orgKey'
import { GlossaryProvider } from '../../context/GlossaryProvider'
import fragmentMatcher from '../../generated/graphql'
import typePolicies from '../../generated/graphql.typePolicies.json'
import useMatch from '../../hooks/useMatch'
import FeatureFlagProvider from '../../providers/FeatureFlagProvider'
import theme from '../../theme'
import { LogLevel, pushLog } from '../../utils/faro'
import { encodeLocationParams } from '../../utils/location'
import { ErrorBoundary } from '../ErrorBoundary'
import ErrorPage from '../ErrorPage'
import { Toaster } from '../Toaster'

const authConfig = {
  userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
}
Auth.configure(authConfig)

// We can easily grab the tenent given the URL format is either https://<TENANT>.<ENVIRONMENT>.playhq.com or https://<TENANT>.playhq.com
const tenant = window.location.hostname.split('.')[0]

/**
 * Find org uuid in path or fallback to localstorage.
 *
 * NOTE: if this is not in a function it will set the value when the page
 * initially redirects  to "/" after login, which results in the orgId being
 * `null` until the page is refreshed.
 */
const getOrganisationId = () =>
  window.location.pathname.match(
    /org\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/,
  )?.[1] || localStorage.getItem(LOCAL_STORAGE_KEY)

const getToken = async () => {
  try {
    const session = await Auth.currentSession()
    const accessToken = session.getAccessToken()
    const token = accessToken.getJwtToken()
    return token
  } catch {
    return null
  }
}

const typePoliciesOverride = {
  /**
   * This adds custom cache ID for ParentDropDownCustomField as
   * ParentDropDownCustomField.id is not guaranteed to be unique
   * and can produce cache collisions
   */
  ParentDropDownCustomField: {
    keyFields: ['id', 'selectedOptionID'],
  },
}

const getApolloClient = () => {
  const cache = new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies: merge(typePolicies, typePoliciesOverride),
  })

  const playmakerHttpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPH_ENDPOINT,
  })

  // Only spectator needs the tenant header set, so it is set explicitly in the
  // http and ws clients, instead of the authlink with the other headers.
  const spectatorHttpLink = new HttpLink({
    uri: process.env.REACT_APP_SPECTATOR_ENDPOINT,
    headers: {
      'X-PHQ-Tenant': tenant,
    },
  })

  // WS Client can not pass http headers from the authLink middleware, need to
  // set them here when the client is created.
  const spectatorWsClient = process.env.REACT_APP_SPECTATOR_WS_ENDPOINT
    ? new GraphQLWsLink(
        createClient({
          url: () =>
            `${process.env.REACT_APP_SPECTATOR_WS_ENDPOINT}?tenant=${tenant}`,
          lazy: true,
          shouldRetry: () => true,
          connectionParams: async () => {
            const token = await getToken()
            const organisationId = getOrganisationId()
            return {
              'X-PHQ-Tenant': tenant,
              'X-Organisation-Id': organisationId,
              Authorization: `Bearer ${token}`,
            }
          },
        }),
      )
    : undefined

  // Split the request to the split clients, depending if it is http or ws.
  const spectatorSplitLink =
    spectatorHttpLink && spectatorWsClient
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          spectatorWsClient,
          spectatorHttpLink,
        )
      : spectatorHttpLink

  // Split the request between playmaker and spectator endpoints
  const splitLink = split(
    operation => operation.getContext().endpoint === 'spectator',
    spectatorSplitLink,
    playmakerHttpLink,
  )

  // Apply authorization headers to the http requests.
  const authLink = setContext(async ({ query }, { headers }) => {
    // Skip headers if the request is a subscription, where the headers are
    // already handled in the WS client.
    const definition = getMainDefinition(query)
    if (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    ) {
      return null
    }

    // Apply authorization headers
    const token = await getToken()
    const organisationId = getOrganisationId()
    return {
      headers: {
        ...headers,
        ...(organisationId && { 'X-Organisation-Id': organisationId }),
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    }
  })

  /**
   * Set localstorage orgId from x-organisation-id field in response headers
   */
  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      if (!localStorage.getItem(LOCAL_STORAGE_KEY)) {
        const context = operation.getContext()
        const orgId = context.response.headers.get('x-organisation-id')
        if (orgId) {
          localStorage.setItem(LOCAL_STORAGE_KEY, orgId)
          window.location.reload()
        }
      }
      return response
    })
  })

  /**
   * Send network errors to grafana.
   * Force logout if auth token is expired.
   */
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (process.env.REACT_APP_GRAFANA_FARO_URL) {
        pushLog(['Apollo network error'], {
          level: LogLevel.ERROR,
          context: {
            errorName: networkError.name,
            errorMessage: networkError.message,
            ...(networkError.stack && { errorStack: networkError.stack }),
          },
        })
      } else {
        // DataDog is still used in production, so keeping it here will allow
        // faro to cutover when the env variable is set.
        datadogRum.addError(networkError)
      }
      networkError.message = 'There was a problem. Please try again.'
    }

    if (graphQLErrors) {
      const unauthError = graphQLErrors.find(
        error =>
          error.message === 'Unauthorised' ||
          error.extensions?.code === 'UNAUTHORISED',
      )

      if (unauthError) {
        navigate('', {
          state: {
            unauthorised: true,
          },
        })
      }

      const insufficientMfaError = graphQLErrors.find(
        error => error.extensions?.code === 'INSUFFICIENT_MFA',
      )

      if (
        insufficientMfaError &&
        !window.location.href.includes('/security/add-mfa')
      ) {
        const requiredMfaType = insufficientMfaError.extensions
          ?.requiredMfa as unknown as string

        const params = encodeLocationParams({
          type: requiredMfaType,
          redirect: window.location.href,
        })

        navigate(`/security/add-mfa${params}`)
      }
    }
  })

  const link = from([authLink, errorLink, afterwareLink, splitLink])

  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy(lastFetchPolicy) {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return process.env.NODE_ENV === 'development'
              ? 'no-cache'
              : 'cache-first'
          }
          return lastFetchPolicy
        },
      },
    },
  })
}

interface StyledThemeProviderProps {
  children: React.ReactNode
}

export const StyledThemeProvider = ({ children }: StyledThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export interface AppProvidersProps {
  children?: React.ReactNode
}

export const AppProviders = ({ children }: AppProvidersProps) => {
  const client = getApolloClient()

  // If the route is outside of the main app flow, playmaker / fed-gateway will
  // return an error (either unauthenticated, or requiring mfa setup). This will
  // cause any queries that are pre-loaded to fail. This `GlossaryProvider`
  // calls a tenant config query, so it will be skipped if the URL is not in the
  // main flow. This needs to be investigated if the platform is
  // internationalised, as it will need a way to fetch this data in all flows.
  const isSecurityRoute = useMatch('security/*')
  const isUnauthenticatedRoute = useMatch('auth/*')
  const skipInitialiseQueries = !!isSecurityRoute || !!isUnauthenticatedRoute

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <FeatureFlagProvider>
        <ApolloProvider client={client}>
          <StyledThemeProvider>
            <GlossaryProvider skipInitialiseQueries={skipInitialiseQueries}>
              <Toaster>{children}</Toaster>
            </GlossaryProvider>
          </StyledThemeProvider>
        </ApolloProvider>
      </FeatureFlagProvider>
    </ErrorBoundary>
  )
}
