import React from 'react'

interface AppcuesLaunchpadProps {
  elementID: string
}

export const AppcuesLaunchpad: React.FunctionComponent<
  AppcuesLaunchpadProps
> = ({ elementID }) => {
  if (window.Appcues) {
    window.Appcues.loadLaunchpad(`#${elementID}`, {
      //Optionally specify the position of the content relative to the Launchpad icon. Possible values are as followed:
      //	- center (default value, i.e. bottom-center)
      //	- left (i.e. bottom-left)
      //	- right (i.e. bottom-right)
      //	- top (i.e. top-center)
      //	- top-left
      //	- top-right
      position: 'top-right',
      // Optionally add a header or footer. This must be HTML.
      header: '<h4>Updates</h4>',
      footer: '',
      // And if you'd prefer to use a custom icon rather than the default "bell" icon, you can optionally pass
      // in an icon as well. Make sure that src is set to the right resource, whether it's in your site's directory or hosted
      // icon: 'icon.png',
    })
  }
  return <div id={elementID} />
}
