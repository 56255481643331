import Loader from '@gameonsports/components/lib/Loader'
import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react'
import get from 'lodash/get'
import merge from 'lodash/merge'
import React, { useContext } from 'react'
import AccountContext from '../../context/AccountContext'
import { FeatureFlag, featureFlags } from '../../flags'

type SdkConfig = {
  features: Partial<Record<FeatureFlag, string>> | undefined
} & Omit<SplitIO.IBrowserSettings, 'features'>

const SDK_CONFIG_OBJECT: SdkConfig = {
  core: {
    authorizationKey:
      process.env.REACT_APP_SPLIT_IO_MOCKS === 'true'
        ? 'localhost'
        : String(process.env.REACT_APP_SPLITIO_API_KEY),
    key: 'default',
    trafficType: 'A_TRAFFIC_TYPE',
  },
  scheduler: {
    featuresRefreshRate: 60,
  },
  debug: process.env.REACT_APP_FEATURE_FLAG_DEBUG === 'true',
  storage: { type: 'LOCALSTORAGE' as 'LOCALSTORAGE' },
  features:
    process.env.REACT_APP_SPLIT_IO_MOCKS === 'true'
      ? {
          ...Object.fromEntries(
            Object.keys(featureFlags).map(ff => [ff, 'on']),
          ),
          // Override feature flag local mocks
          // featureFlagName: 'off',
          // 'environment-labels': 'off',
          'ca-admin-account-linking': 'off',
          'kill-login': 'off',
          'search-filters-registration-date-kill': 'off',
        }
      : undefined,
}

const FeatureFlagProvider: React.FC = ({ children }) => {
  const { user } = useContext(AccountContext)
  const userId = get(user, 'id', '').substr(-8)

  return (
    <SplitFactory
      config={merge(SDK_CONFIG_OBJECT, {
        core: { key: userId || 'anonymous' },
      })}
    >
      <SplitTreatments names={Object.keys(featureFlags)}>
        {data =>
          data.isReady || data.isReadyFromCache || data.isTimedout ? (
            <>{children}</>
          ) : (
            <Loader size={3} />
          )
        }
      </SplitTreatments>
    </SplitFactory>
  )
}

export default FeatureFlagProvider
