import { FeatureFlag } from '../flags'
import useFeatureFlag, {
  SplitAttributeTypes,
  SplitAttributes,
} from './useFeatureFlag'

export default (
  name: FeatureFlag,
  attributes: Partial<Record<SplitAttributes, SplitAttributeTypes>> = {},
) => {
  const [treatment] = useFeatureFlag(name, attributes)
  return treatment === 'on'
}
