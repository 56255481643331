// @ts-ignore
import { match, resolve } from '@reach/router/lib/utils'

type MatchReturn<TParams> = null | ({ uri: string; path: string } & TParams)

const useMatch = <TParams = {}>(path: string): MatchReturn<TParams> => {
  const resolvedPath = resolve(path, '/')
  const result = match(resolvedPath, window.location.pathname)
  return result
    ? {
        ...result.params,
        uri: result.uri,
        path,
      }
    : null
}

export default useMatch
