import { Location } from '@reach/router'
import React from 'react'
import ErrorPanel from '../ErrorPanel'
import UnauthorisedBoundary, {
  UnauthorisedError,
} from '../UnauthorisedBoundary'

interface AuthorisationPageErrorBoundaryProps {
  href: string
}
interface State {
  error: Error | undefined
}

class AuthorisationPageErrorBoundary extends React.Component<
  AuthorisationPageErrorBoundaryProps,
  State
> {
  state: State = {
    error: undefined,
  }

  componentDidCatch(error: Error) {
    if (error instanceof UnauthorisedError) {
      this.setState({ error })
      return
    }

    // Bubble the error up
    throw error
  }

  componentDidUpdate(prevProps: AuthorisationPageErrorBoundaryProps) {
    // Remove error when navigating between pages
    if (prevProps.href !== this.props.href) {
      this.setState({ error: undefined })
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error === undefined) {
      return <UnauthorisedBoundary>{children}</UnauthorisedBoundary>
    }

    return (
      <ErrorPanel
        title="401"
        description="You don't have access to view this. Please select from the organisation(s) you have access to in the menu."
        data-testid="page-unauthorised-error"
      />
    )
  }
}

const AuthorisationPageErrorBoundaryContainer: React.FC<{}> = ({
  children,
}) => (
  <Location>
    {({ location }) => (
      <AuthorisationPageErrorBoundary href={location.href}>
        {children}
      </AuthorisationPageErrorBoundary>
    )}
  </Location>
)

export default AuthorisationPageErrorBoundaryContainer
