import Button from '@gameonsports/components/lib/Button'
import Icon from '@gameonsports/components/lib/Icon'
import { Stack } from '@gameonsports/components/lib/StackV2'
import { Text } from '@gameonsports/components/lib/TextV3'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

type ToastPosition = 'left' | 'center'
type ToastVariant = 'primary' | 'error' | 'success'

export interface ToastType {
  id?: string
  title: string
  description?: string
  onConfirm?: () => void
  onCancel?: () => void
  confirmLabel?: string
  position?: ToastPosition
  variant?: ToastVariant
  timeout?: number
}

const transitionIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Container = styled.section<{
  $position: ToastPosition
  $variant: ToastVariant
}>`
  position: relative;
  left: 1rem;
  background-color: ${props => props.theme.black400};
  border: 1px solid ${props => props.theme.darkGrey400};
  border-radius: 0.25rem;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
  color: ${props => props.theme.white400};
  min-width: 20rem;
  width: max-content;
  padding: 1rem;

  display: grid;
  grid-gap: 0.5rem;

  & > * {
    margin: 0;
  }

  animation-name: ${transitionIn};
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  ${props =>
    props.$variant === 'success' &&
    css`
      background-color: ${props => props.theme.kiwi400};
      border: 1px solid ${props => props.theme.kiwi400};
    `}

  ${props =>
    props.$variant === 'error' &&
    css`
      background-color: ${props => props.theme.error};
      border: 1px solid ${props => props.theme.error};
    `}
    
  ${props =>
    props.$position === 'center' &&
    css`
      margin: 0 auto;
    `}
`

const Cluster = styled.section`
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;

  & > * + * {
    margin-left: 1rem;
  }
`

export const Toast: React.FC<ToastType> = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmLabel = 'Ok',
  position = 'left',
  variant = 'primary',
}) => {
  return (
    <Container $position={position} $variant={variant}>
      <Stack direction="row" alignItems="center" gap="m">
        {variant === 'success' && <Icon name="tick" color="white400" />}
        {variant === 'error' && <Icon name="cross" color="white400" />}
        <Text size="18">{title}</Text>
      </Stack>
      {description && <p>{description}</p>}
      {(onConfirm || onCancel) && (
        <Cluster>
          {onCancel && (
            <Button type="button" onClick={onCancel} variant="tertiary">
              Cancel
            </Button>
          )}
          {onConfirm && (
            <Button type="button" onClick={onConfirm}>
              {confirmLabel}
            </Button>
          )}
        </Cluster>
      )}
    </Container>
  )
}
