import useAuthentication from '@gameonsports/aws-amplify-hooks/lib/useAuthentication'
import Loader from '@gameonsports/components/lib/Loader'
import React from 'react'

const AuthenticationBoundary: React.FunctionComponent<{
  onAuthenticated?: () => void
  onUnauthenticated?: () => void
}> = ({ children, onAuthenticated, onUnauthenticated }) => {
  const { checked, authenticated } = useAuthentication()

  if (checked && authenticated && onAuthenticated) {
    onAuthenticated()
  }

  if (checked && !authenticated && onUnauthenticated) {
    onUnauthenticated()
  }

  if (checked) {
    return <>{children}</>
  }

  return <Loader />
}

export default AuthenticationBoundary
