import { BaseThemedCssFunction, css } from 'styled-components'

// tslint:disable-next-line ban-types
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never

const mediaQuery =
  (...query: ArgumentTypes<BaseThemedCssFunction<any>>) =>
  (...rules: ArgumentTypes<BaseThemedCssFunction<any>>) =>
    css`
      @media ${css(...query)} {
        ${css(...rules)}
      }
    `

/** @deprecated Use theme.breakpoints from @gameonsports/components/theme */
const sizes = {
  mobile: 480,
  tablet: 768,
  desktop: 1244,
  largeDesktop: 1480,
}

interface MediaSizes {
  mobile: BaseThemedCssFunction<any>
  tablet: BaseThemedCssFunction<any>
  desktop: BaseThemedCssFunction<any>
  largeDesktop: BaseThemedCssFunction<any>
}

/** @deprecated Use mq from @gameonsports/components/utils/styled-components-utils */
export const media = Object.entries(sizes).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: mediaQuery`(min-width: ${value / 16}em)`,
  }),
  {} as MediaSizes,
)
