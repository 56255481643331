import Loader from '@gameonsports/components/lib/Loader'
import {
  Location,
  Match,
  Redirect,
  RouteComponentProps,
  Router,
  RouterProps,
  WindowLocation,
  navigate,
} from '@reach/router'
import isEqual from 'lodash/isEqual'
import React, { Suspense, lazy, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import AppPreloader from '../../components/AppPreloader'
import AuthenticationBoundary from '../../components/AuthenticationBoundary'
import AuthorisationPageErrorBoundary from '../../components/AuthorisationPageErrorBoundary'
import Global from '../../components/Global'
import Navigation from '../../components/Navigation'
import PageErrorBoundary from '../../components/PageErrorBoundary'
import {
  OrganisationType,
  useLinkedExternalAccountQuery,
  useTenantConfigurationHasExternalVouchersQuery,
} from '../../generated/graphql'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import useMatch from '../../hooks/useMatch'
import useOrg from '../../hooks/useOrg'
import useOrgType from '../../hooks/useOrgType'
import useRole from '../../hooks/useRole'
import { media } from '../../utils/styled-components-utils'

const Icons = lazy(
  () =>
    import(
      '@gameonsports/components/lib/Icons' /* webpackChunkName: "Icons" */
    ),
)

const NotFound = lazy(() => import('../404' /* webpackChunkName: "404" */))

const Login = lazy(
  () => import('../Login/Login' /* webpackChunkName: "Login" */),
)

const ExternalAccountLinking = lazy(
  () =>
    import(
      '../ExternalAccountLinking/ExternalAccountLinking' /* webpackChunkName: "ExternalAccountLinking" */
    ),
)

const Logout = lazy(() => import('../Logout' /* webpackChunkName: "Logout" */))

const Signup = lazy(() => import('../Signup' /* webpackChunkName: "Signup" */))

const ForgotPassword = lazy(
  () => import('../ForgotPassword' /* webpackChunkName: "ForgotPassword" */),
)

const ResetPassword = lazy(
  () => import('../ResetPassword' /* webpackChunkName: "ResetPassword" */),
)

const RoundCreate = lazy(
  () => import('../RoundCreate' /* webpackChunkName: "RoundCreate" */),
)

const AccountOverview = lazy(
  () => import('../AccountOverview' /* webpackChunkName: "Account" */),
)

const AccountSecurity = lazy(
  () => import('../AccountSecurity' /* webpackChunkName: "AccountSecurity" */),
)

const MfaSetup = lazy(
  () => import('../MfaSetup' /* webpackChunkName: "MfaSetup" */),
)

const MfaSetupRequired = lazy(
  () =>
    import('../MfaSetupRequired' /* webpackChunkName: "MfaSetupRequired" */),
)

const AccountDetails = lazy(
  () =>
    import(
      '../AccountDetails/AccountDetails' /* webpackChunkName: "AccountDetails" */
    ),
)

const MyOrganisationOverview = lazy(
  () =>
    import(
      '../MyOrganisationOverview' /* webpackChunkName: "MyOrganisation" */
    ),
)

const MyOrganisationDetails = lazy(
  () =>
    import(
      '../MyOrganisationDetails' /* webpackChunkName: "MyOrganisationDetails" */
    ),
)

const MyOrganisationContacts = lazy(
  () =>
    import(
      '../MyOrganisationContacts' /* webpackChunkName: "MyOrganisationContacts" */
    ),
)

const MyOrganisationVouchers = lazy(
  () =>
    import(
      '../MyOrganisationVouchers' /* webpackChunkName: "MyOrganisationVouchers" */
    ),
)

const MyOrganisationMemberships = lazy(
  () =>
    import(
      '../MyOrganisationMemberships' /* webpackChunkName: "MyOrganisationMemberships" */
    ),
)

const MyOrganisationNotifications = lazy(
  () =>
    import(
      '../MyOrganisationNotifications' /* webpackChunkName: "MyOrgnisationNotifications" */
    ),
)

const MyOrganisationPayment = lazy(
  () =>
    import(
      '../MyOrganisationPayment' /* webpackChunkName: "MyOrganisationPayment" */
    ),
)

const MyOrganisationSponsorshipAdvertising = lazy(
  () =>
    import(
      '../MyOrganisationSponsorshipAdvertising' /* webpackChunkName: "MyOrganisationSponsorshipAdvertising" */
    ),
)

const MyOrganisationBankAccountEdit = lazy(
  () =>
    import(
      '../MyOrganisationBankAccountEdit' /* webpackChunkName: "MyOrganisationBankAccountEdit" */
    ),
)

const RoleMembershipCreate = lazy(
  () =>
    import(
      '../RoleMembershipCreate' /* webpackChunkName: "RoleMembershipCreate" */
    ),
)

const RoleMembershipEdit = lazy(
  () =>
    import(
      '../RoleMembershipEdit' /* webpackChunkName: "RoleMembershipEdit" */
    ),
)

const SubmitPaymentDetails = lazy(
  () =>
    import(
      '../SubmitPaymentDetails' /* webpackChunkName: "SubmitPaymentDetails" */
    ),
)

const VoucherCreate = lazy(
  () => import('../VoucherCreate' /* webpackChunkName: "VoucherCreate" */),
)

const VoucherEdit = lazy(
  () => import('../VoucherEdit' /* webpackChunkName: "VoucherEdit" */),
)

const Organisations = lazy(
  () => import('../Organisations' /* webpackChunkName: "Organisations" */),
)

const OrganisationList = lazy(
  () =>
    import('../OrganisationList' /* webpackChunkName: "OrganisationList" */),
)

const Organisation = lazy(
  () => import('../Organisation' /* webpackChunkName: "Organisation" */),
)

const OrganisationSettings = lazy(
  () =>
    import(
      '../OrganisationSettings' /* webpackChunkName: "OrganisationSettings" */
    ),
)

const OrganisationApiKeys = lazy(
  () =>
    import(
      '../OrganisationApiKeys' /* webpackChunkName: "OrganisationApiKeys" */
    ),
)

const OrganisationApiKeyCreate = lazy(
  () =>
    import(
      '../OrganisationApiKeyCreate' /* webpackChunkName: "OrganisationApiKeyCreate" */
    ),
)

const OrganisationCreate = lazy(
  () =>
    import(
      '../OrganisationCreate' /* webpackChunkName: "OrganisationCreate" */
    ),
)

const Admins = lazy(() => import('../Admins' /* webpackChunkName: "Admins" */))

const AdminList = lazy(
  () => import('../AdminList' /* webpackChunkName: "AdminList" */),
)

const AdminEdit = lazy(
  () => import('../AdminEdit' /* webpackChunkName: "AdminEdit" */),
)

const AdminAccessHistoryList = lazy(
  () =>
    import(
      '../AdminAccessHistoryList' /* webpackChunkName: "AdminAccessHistoryList" */
    ),
)

const AdminInviteCreate = lazy(
  () =>
    import('../AdminInviteCreate' /* webpackChunkName: "AdminInviteCreate" */),
)

const AllLadders = lazy(() => import('../AllLadders' /* AllLadders */))

const Competitions = lazy(
  () => import('../Competitions' /* webpackChunkName: "Competitions" */),
)

const ClubCompetitions = lazy(
  () =>
    import('../ClubCompetitions' /* webpackChunkName: "ClubCompetitions" */),
)

const Competition = lazy(
  () => import('../Competition' /* webpackChunkName: "Competition" */),
)

const CompetitionCreate = lazy(
  () =>
    import('../CompetitionCreate' /* webpackChunkName: "CompetitionCreate" */),
)

const SeasonCreate = lazy(
  () => import('../SeasonCreate' /* webpackChunkName: "SeasonCreate" */),
)

const SeasonRollover = lazy(
  () => import('../SeasonRollover' /* webpackChunkName: "SeasonRollover" */),
)

const SeasonList = lazy(
  () => import('../SeasonList' /* webpackChunkName: "SeasonList" */),
)

const Season = lazy(() => import('../Season' /* webpackChunkName: "Season" */))

const SeasonSettings = lazy(
  () => import('../SeasonSettings' /* webpackChunkName: "SeasonSettings" */),
)

const FixtureUpload = lazy(
  () => import('../FixtureUpload' /* webpackChunkName: "FixtureUpload" */),
)

const SeasonParticipants = lazy(
  () =>
    import(
      '../SeasonParticipants' /* webpackChunkName: "SeasonParticipants" */
    ),
)

const SeasonPlayerPoints = lazy(
  () =>
    import(
      '../SeasonPlayerPoints' /* webpackChunkName: "SeasonPlayerPoints" */
    ),
)

const Registrations = lazy(
  () => import('../Registrations' /* webpackChunkName: "Registrations" */),
)

const SeasonAgeGroupDetails = lazy(
  () =>
    import(
      '../SeasonAgeGroupDetails' /* webpackChunkName: "SeasonAgeGroupDetails" */
    ),
)

const SeasonRegistrations = lazy(
  () =>
    import(
      '../SeasonRegistrations' /* webpackChunkName: "SeasonRegistrations" */
    ),
)

const SeasonClubSettings = lazy(
  () =>
    import(
      '../SeasonClubSettings' /* webpackChunkName: "SeasonClubSettings" */
    ),
)

const SeasonClubSettingsConfigure = lazy(
  () =>
    import(
      '../SeasonClubSettingsConfigure' /* webpackChunkName: "SeasonClubSettingsConfigure" */
    ),
)

const SeasonCustomFields = lazy(
  () =>
    import(
      '../SeasonCustomFields' /* webpackChunkName: "SeasonCustomFields" */
    ),
)

const SeasonAgeGroups = lazy(
  () => import('../SeasonAgeGroups' /* webpackChunkName: "SeasonAgeGroups" */),
)

const CustomFieldCreate = lazy(
  () =>
    import('../CustomFieldCreate' /* webpackChunkName: "CustomFieldCreate" */),
)

const CustomFieldEdit = lazy(
  () => import('../CustomFieldEdit' /* webpackChunkName: "CustomFieldEdit" */),
)

const ProgramCustomFieldCreate = lazy(
  () =>
    import(
      '../ProgramCustomFieldCreate' /* webpackChunkName: "ProgramCustomFieldCreate" */
    ),
)

const ProgramCustomFieldEdit = lazy(
  () =>
    import(
      '../ProgramCustomFieldEdit' /* webpackChunkName: "ProgramCustomFieldEdit" */
    ),
)

const ProgramList = lazy(
  () => import('../ProgramList' /* webpackChunkName: "ProgramList" */),
)

const ProgramCreate = lazy(
  () => import('../ProgramCreate' /* webpackChunkName: "ProgramCreate" */),
)

const Program = lazy(
  () => import('../Program' /* webpackChunkName: "Program" */),
)

const ProgramSeasonList = lazy(
  () =>
    import('../ProgramSeasonList' /* webpackChunkName: "ProgramSeasonList" */),
)

const ProgramSeasonCreate = lazy(
  () =>
    import(
      '../ProgramSeasonCreate' /* webpackChunkName: "ProgramSeasonCreate" */
    ),
)

const ProgramSettingsDetails = lazy(
  () =>
    import(
      '../ProgramSettingsDetails' /* webpackChunkName: "ProgramSettingsDetails" */
    ),
)

const ProgramSeason = lazy(
  () => import('../ProgramSeason' /* webpackChunkName: "ProgramSeason" */),
)

const ProgramRegistration = lazy(
  () =>
    import(
      '../ProgramRegistration' /* webpackChunkName: "ProgramRegistration" */
    ),
)

const ProgramRegistrationCustomFields = lazy(
  () =>
    import(
      '../ProgramRegistrationCustomFields' /* webpackChunkName: "ProgramRegistrationCustomFields" */
    ),
)

const ProgramRegistrationLimits = lazy(
  () =>
    import(
      '../ProgramRegistrationLimits' /* webpackChunkName: "ProgramRegistrationLimits" */
    ),
)

const ProgramRegistrationLimitDetails = lazy(
  () =>
    import(
      '../ProgramRegistrationLimitDetails' /* webpackChunkName: "ProgramRegistrationLimitDetails" */
    ),
)

const ProgramRegistrationSettings = lazy(
  () =>
    import(
      '../ProgramRegistrationSettings' /* webpackChunkName: "ProgramRegistrationSettings" */
    ),
)

const ProgramSeasonSettings = lazy(
  () =>
    import(
      '../ProgramSeasonSettings' /* webpackChunkName: "ProgramSeasonSettings" */
    ),
)

const ProgramParticipantsList = lazy(
  () =>
    import(
      '../ProgramParticipantsList' /* webpackChunkName: "ProgramParticipantsList" */
    ),
)

const ProgramGroupsList = lazy(
  () =>
    import('../ProgramGroupsList' /* webpackChunkName: "ProgramGroupsList" */),
)

const ProgramGroupCreate = lazy(
  () =>
    import(
      '../ProgramGroupCreate' /* webpackChunkName: "ProgramGroupCreate" */
    ),
)

const ProgramGroupEdit = lazy(
  () =>
    import('../ProgramGroupEdit' /* webpackChunkName: "ProgramGroupEdit" */),
)

const ProgramGroup = lazy(
  () => import('../ProgramGroup' /* webpackChunkName: "ProgramGroup" */),
)

const ProgramSeasonOrganisations = lazy(
  () =>
    import(
      '../ProgramSeasonOrganisations' /* webpackChunkName: "ProgramSeasonOrganisations" */
    ),
)

const ParticipantToProgramCreate = lazy(
  () =>
    import(
      '../ParticipantToProgramCreate' /* webpackChunkName: "ParticipantToProgramCreate" */
    ),
)

const ParticipantToProgramEdit = lazy(
  () =>
    import(
      '../ParticipantToProgramEdit' /* webpackChunkName: "ParticipantToProgramEdit" */
    ),
)

const OrganisationToProgramCreate = lazy(
  () =>
    import(
      '../OrganisationToProgramCreate' /* webpackChunkName: "OrganisationToProgramCreate" */
    ),
)

const OrganisationToProgramEdit = lazy(
  () =>
    import(
      '../OrganisationToProgramEdit' /* webpackChunkName: "OrganisationToProgramEdit" */
    ),
)

const ParticipantToOrganisationCreate = lazy(
  () =>
    import(
      '../ParticipantToOrganisationCreate' /* webpackChunkName: "ParticipantToOrganisationCreate" */
    ),
)

const ParticipantToOrganisationEdit = lazy(
  () =>
    import(
      '../ParticipantToOrganisationEdit' /* webpackChunkName: "ParticipantToOrganisationEdit" */
    ),
)

const SocialTeamToSeasonCreate = lazy(
  () =>
    import(
      '../SocialTeamToSeasonCreate' /* webpackChunkName: "SocialTeamToSeasonCreate" */
    ),
)

const SocialTeamToSeasonEdit = lazy(
  () =>
    import(
      '../SocialTeamToSeasonEdit' /* webpackChunkName: "SocialTeamToSeasonEdit" */
    ),
)

const ParticipantToTeamCreate = lazy(
  () =>
    import(
      '../ParticipantToTeamCreate' /* webpackChunkName: "ParticipantToTeamCreate" */
    ),
)

const ParticipantToTeamEdit = lazy(
  () =>
    import(
      '../ParticipantToTeamEdit' /* webpackChunkName: "ParticipantToTeamEdit" */
    ),
)

const FamilyMemberDiscountCreate = lazy(
  () =>
    import(
      '../FamilyMemberDiscountCreate' /* webpackChunkName: "FamilyMemberDiscountCreate" */
    ),
)

const FamilyMemberDiscountEdit = lazy(
  () =>
    import(
      '../FamilyMemberDiscountEdit' /* webpackChunkName: "FamilyMemberDiscountEdit" */
    ),
)

const CompetitionSettings = lazy(
  () =>
    import(
      '../CompetitionSettings' /* webpackChunkName: "CompetitionSettings" */
    ),
)

const CompetitionSettingsDetails = lazy(
  () =>
    import(
      '../CompetitionSettingsDetails' /* webpackChunkName: "CompetitionSettingsDetails" */
    ),
)

const CompetitionSettingsGradeDefaults = lazy(
  () =>
    import(
      '../CompetitionSettingsGradeDefaults' /* webpackChunkName: "CompetitionSettingsGradeDefaults" */
    ),
)

const CompetitionList = lazy(
  () => import('../CompetitionList' /* webpackChunkName: "CompetitionList" */),
)

const GradeList = lazy(
  () => import('../GradeList' /* webpackChunkName: "GradeList" */),
)

const GradeCreate = lazy(
  () => import('../GradeCreate' /* webpackChunkName: "GradeCreate" */),
)

const GradeRollover = lazy(
  () => import('../GradeRollover' /* webpackChunkName: "GradeRollover" */),
)

const Grade = lazy(() => import('../Grade' /* webpackChunkName: "Grade" */))

const GradeLadder = lazy(
  () => import('../GradeLadder' /* webpackChunkName: "GradeLadder" */),
)

const GradeLadderAdjustment = lazy(
  () =>
    import(
      '../GradeLadderAdjustment' /* webpackChunkName: "GradeLadderAdjustment" */
    ),
)

const GradeExceptionDatesEdit = lazy(
  () =>
    import(
      '../GradeExceptionDatesEdit' /* webpackChunkName: "GradeExceptionDatesEdit" */
    ),
)

const GradeExceptionDatesGenerate = lazy(
  () =>
    import(
      '../GradeExceptionDatesGenerate' /* webpackChunkName: "GradeExceptionDatesGenerate" */
    ),
)

const GradeSettings = lazy(
  () => import('../GradeSettings' /* webpackChunkName: "GradeSettings" */),
)

const GradeSettingsGeneral = lazy(
  () =>
    import(
      '../GradeSettingsGeneral' /* webpackChunkName: "GradeSettingsGeneral" */
    ),
)

const GradeSettingsGame = lazy(
  () =>
    import('../GradeSettingsGame' /* webpackChunkName: "GradeSettingsGame" */),
)

const GradeSettingsLadder = lazy(
  () =>
    import(
      '../GradeSettingsLadder' /* webpackChunkName: "GradeSettingsLadder" */
    ),
)

const GradeSettingsFixturing = lazy(
  () =>
    import(
      '../GradeSettingsFixturing' /* webpackChunkName: "GradeSettingsFixturing" */
    ),
)

const GradeSettingsRegradeHistory = lazy(
  () =>
    import(
      '../GradeSettingsRegradeHistory' /* webpackChunkName: "GradeSettingsRegradeHistory" */
    ),
)

const GradePools = lazy(
  () => import('../GradePools' /* webpackChunkName: "GradePools" */),
)

const FixtureGenerate = lazy(
  () => import('../FixtureGenerate' /* webpackChunkName: "FixtureGenerate" */),
)

const FixtureRoundSettingsReview = lazy(
  () =>
    import(
      '../FixtureRoundSettingsReview' /* webpackChunkName: "FixtureRoundSettingsReview" */
    ),
)

const FinalsRoundSettingsReview = lazy(
  () =>
    import(
      '../FinalsRoundSettingsReview' /* webpackChunkName: "FinalsRoundSettingsReview" */
    ),
)

const FixtureGenerateHomeAndAway = lazy(
  () =>
    import(
      '../FixtureGenerateHomeAndAway' /* webpackChunkName: "FixtureGenerateHomeAndAway" */
    ),
)

const TeamAward = lazy(
  () => import('../TeamAward' /* webpackChunkName: "TeamAward" */),
)

const TeamAwardConfigure = lazy(
  () =>
    import(
      '../TeamAwardConfigure' /* webpackChunkName: "TeamAwardConfigure" */
    ),
)

const TeamList = lazy(
  () => import('../TeamList' /* webpackChunkName: "TeamList" */),
)

const TeamCreate = lazy(
  () => import('../TeamCreate' /* webpackChunkName: "TeamCreate" */),
)

const TeamRollover = lazy(
  () => import('../TeamRollover' /* webpackChunkName: "TeamRollover" */),
)

const Team = lazy(() => import('../Team' /* webpackChunkName: "Team" */))

const TeamDetailList = lazy(
  () => import('../TeamDetailList' /* webpackChunkName: "TeamDetailList" */),
)

const TeamRegistrationsList = lazy(
  () =>
    import(
      '../TeamRegistrationsList' /* webpackChunkName: "TeamRegistrationsList" */
    ),
)

const TeamFinalsEligibilityOverride = lazy(
  () =>
    import(
      '../TeamFinalsEligibilityOverride' /* webpackChunkName: "TeamFinalsEligibilityOverride" */
    ),
)

const TeamGradeRestrictionsOverride = lazy(
  () =>
    import(
      '../TeamGradeRestrictionsOverride' /* webpackChunkName: "TeamGradeRestrictionsOverride" */
    ),
)

const TeamCustomFieldsEdit = lazy(
  () =>
    import(
      '../TeamCustomFieldsEdit' /* webpackChunkName: "TeamCustomFieldsEdit" */
    ),
)

const TeamEdit = lazy(
  () => import('../TeamEdit' /* webpackChunkName: "TeamEdit" */),
)

const TeamPlayerNumbers = lazy(
  () =>
    import('../TeamPlayerNumbers' /* webpackChunkName: "TeamPlayerNumbers" */),
)

const Settings = lazy(
  () => import('../Settings' /* webpackChunkName: "Settings" */),
)

const SettingsGeneral = lazy(
  () => import('../SettingsGeneral' /* webpackChunkName: "SettingsGeneral" */),
)

const SettingsVouchers = lazy(
  () =>
    import('../SettingsVouchers' /* webpackChunkName: "SettingsVouchers" */),
)

const SettingsIntegrations = lazy(
  () =>
    import(
      '../SettingsIntegrations' /* webpackChunkName: "SettingsIntegrations" */
    ),
)

const SettingsPermits = lazy(
  () => import('../SettingsPermits' /* webpackChunkName: "SettingsPermits" */),
)

const SeasonAwardsConfigure = lazy(
  () =>
    import(
      '../SeasonAwardsConfigure' /* webpackChunkName: "SeasonAwardsConfigure" */
    ),
)

const SeasonPermitSettings = lazy(
  () =>
    import(
      '../SeasonPermitSettings' /* webpackChunkName: "SeasonPermitSettings" */
    ),
)

const SeasonFixturing = lazy(
  () => import('../SeasonFixturing' /* webpackChunkName: "SeasonFixturing" */),
)

const SeasonOffenceList = lazy(
  () =>
    import('../SeasonOffenceList' /* webpackChunkName: "SeasonOffenceList" */),
)

const PermitAgreementList = lazy(
  () =>
    import(
      '../PermitAgreementList' /* webpackChunkName: "PermitAgreementList" */
    ),
)

const PermitAgreementCreate = lazy(
  () =>
    import(
      '../PermitAgreementCreate' /* webpackChunkName: "PermitAgreementCreate" */
    ),
)

const PermitAgreementEdit = lazy(
  () =>
    import(
      '../PermitAgreementEdit' /* webpackChunkName: "PermitAgreementEdit" */
    ),
)

const SettingsTransfers = lazy(
  () =>
    import('../SettingsTransfers' /* webpackChunkName: "SettingsTransfers" */),
)

const TransferExceptionPeriodList = lazy(
  () =>
    import(
      '../TransferExceptionPeriodList' /* webpackChunkName: "TransferExceptionPeriodList" */
    ),
)

const TransferExceptionPeriodCreate = lazy(
  () =>
    import(
      '../TransferExceptionPeriodCreate' /* webpackChunkName: "TransferExceptionPeriodCreate" */
    ),
)

const TransferExceptionPeriodEdit = lazy(
  () =>
    import(
      '../TransferExceptionPeriodEdit' /* webpackChunkName: "TransferExceptionPeriodEdit" */
    ),
)

const SettingsCodesOfConduct = lazy(
  () =>
    import(
      '../SettingsCodesOfConduct' /* webpackChunkName: "SettingsCodesOfConduct" */
    ),
)

const CodeOfConductCreate = lazy(
  () =>
    import(
      '../CodeOfConductCreate' /* webpackChunkName: "CodeOfConductCreate" */
    ),
)

const CodeOfConductEdit = lazy(
  () =>
    import('../CodeOfConductEdit' /* webpackChunkName: "CodeOfConductEdit" */),
)

const MailchimpIntegration = lazy(
  () =>
    import(
      '../MailchimpIntegration' /* webpackChunkName: "MailchimpIntegration" */
    ),
)

const MailchimpConnect = lazy(
  () =>
    import('../MailchimpConnect' /* webpackChunkName: "MailchimpConnect" */),
)

const XeroIntegration = lazy(
  () => import('../XeroIntegration' /* webpackChunkName: "XeroIntegration" */),
)

const XeroConnect = lazy(
  () => import('../XeroConnect' /* webpackChunkName: "XeroConnect" */),
)

const Maintenance = lazy(
  () => import('../Maintenance' /* webpackChunkName: "Maintenance" */),
)

const AdminBodyCustomFields = lazy(
  () =>
    import(
      '../AdminBodyCustomFields' /* webpackChunkName: "AdminBodyCustomFields" */
    ),
)

const AdminBodyCustomFieldsCreate = lazy(
  () =>
    import(
      '../AdminBodyCustomFieldsCreate' /* webpackChunkName: "AdminBodyCustomFieldsCreate" */
    ),
)

const AdminBodyCustomFieldsEdit = lazy(
  () =>
    import(
      '../AdminBodyCustomFieldsEdit' /* webpackChunkName: "AdminBodyCustomFieldsEdit" */
    ),
)

const Venues = lazy(() => import('../Venues' /* webpackChunkName: "Venues" */))

const VenueList = lazy(
  () => import('../VenueList' /* webpackChunkName: "VenueList" */),
)

const VenueCreate = lazy(
  () => import('../VenueCreate' /* webpackChunkName: "VenueCreate" */),
)

const Venue = lazy(() => import('../Venue' /* webpackChunkName: "Venue" */))

const VenueEdit = lazy(
  () => import('../VenueEdit' /* webpackChunkName: "VenueEdit" */),
)

const Fixture = lazy(
  () => import('../Fixture' /* webpackChunkName: "Fixture" */),
)

const FixtureRegular = lazy(
  () => import('../FixtureRegular' /* webpackChunkName: "FixtureRegular" */),
)

const FixtureFinals = lazy(
  () => import('../FixtureFinals' /* webpackChunkName: "FixtureFinals" */),
)

const FixtureReview = lazy(
  () => import('../FixtureReview' /* webpackChunkName: "FixtureReview" */),
)

const GameEdit = lazy(
  () => import('../GameEdit' /* webpackChunkName: "GameEdit" */),
)

const GameCreate = lazy(
  () => import('../GameCreate' /* webpackChunkName: "GameCreate" */),
)

const FinalsGenerate = lazy(
  () => import('../FinalsGenerate' /* webpackChunkName: "FinalsGenerate" */),
)

const FinalsReview = lazy(
  () => import('../FinalsReview' /* webpackChunkName: "FinalsReview" */),
)

const ParticipantsSearch = lazy(
  () =>
    import(
      '../ParticipantsSearch' /* webpackChunkName: "ParticipantsSearch" */
    ),
)

const Posts = lazy(() => import('../Posts' /* webpackChunkName: Posts */))

const PostCreate = lazy(
  () => import('../PostCreate' /* webpackChunkName: PostCreate */),
)

const PostEdit = lazy(
  () => import('../PostEdit' /* webpackChunkName: PostEdit */),
)

const Reports = lazy(
  () => import('../Reports' /* webpackChunkName: "Reports" */),
)

const ReportsFinancial = lazy(
  () =>
    import('../ReportsFinancial' /* webpackChunkName: "ReportsFinancial" */),
)

const ReportsOrders = lazy(
  () => import('../ReportsOrders' /* webpackChunkName: "ReportsOrders" */),
)

const ReportsDiscipline = lazy(
  () =>
    import('../ReportsDiscipline' /* webpackChunkName: "ReportsDiscipline" */),
)

const ReportsCompetitions = lazy(
  () =>
    import(
      '../ReportsCompetitions' /* webpackChunkName: "ReportsCompetitions" */
    ),
)

const ReportsCompetitionsCustomise = lazy(
  () =>
    import(
      '../ReportsCompetitionsCustomise' /* webpackChunkName: "ReportsCompetitionsCustomise" */
    ),
)

const ReportsFinancialCustomise = lazy(
  () =>
    import(
      '../ReportsFinancialCustomise' /* webpackChunkName: "ReportsFinancialCustomise" */
    ),
)

const ReportsProgramParticipantCustomise = lazy(
  () =>
    import(
      '../ReportsProgramParticipantCustomise' /* webpackChunkName: ReportsProgramParticipantCustomise */
    ),
)

const ReportsStatistics = lazy(
  () =>
    import(
      '../ReportsStatistics' /* webpackChunkName: "ReportsCompetitions" */
    ),
)

const ReportsTransfers = lazy(
  () =>
    import('../ReportsTransfers' /* webpackChunkName: "ReportsTransfers" */),
)

const ReportsPrograms = lazy(
  () => import('../ReportsPrograms' /* webpackChunkName: "ReportsPrograms" */),
)

const ReportsGenerated = lazy(
  () =>
    import('../ReportsGenerated' /* webpackChunkName: "ReportsGenerated" */),
)

const ReportsGenerate = lazy(
  () => import('../ReportsGenerate' /* webpackChunkName: "ReportsGenerate" */),
)

const ReportVenue = lazy(
  () => import('../ReportVenue' /* webpackChunkName: "ReportVenue" */),
)

const ReportTeam = lazy(
  () => import('../ReportTeam' /* webpackChunkName: "ReportTeam" */),
)

const Participant = lazy(
  () => import('../Participant' /* webpackChunkName: "Participant" */),
)

const ParticipantDuplicateComparison = lazy(
  () =>
    import(
      '../ParticipantDuplicateComparison' /* webpackChunkName: "ParticipantDuplicateComparison" */
    ),
)

const ParticipantDuplicateManagement = lazy(
  () =>
    import(
      '../ParticipantDuplicateManagement' /* webpackChunkName: "ParticipantDuplicateManagement" */
    ),
)

const ParticipantRevertAffiliation = lazy(
  () =>
    import(
      '../ParticipantRevertAffiliation' /* webpackChunkName: "ParticipantRevertAffiliation" */
    ),
)

const ParticipantEditDetails = lazy(
  () =>
    import(
      '../ParticipantEditDetails' /* webpackChunkName: "ParticipantEditDetails" */
    ),
)

const ParticipantCustomFieldsEdit = lazy(
  () =>
    import(
      '../ParticipantCustomFieldsEdit' /* webpackChunkName: "ParticipantCustomFieldsEdit" */
    ),
)

const ParticipantProgramTransfer = lazy(
  () =>
    import(
      '../ParticipantProgramTransfer' /* webpackChunkName: "ParticipantProgramTransfer" */
    ),
)

const PlayingSurfaceList = lazy(
  () =>
    import(
      '../PlayingSurfaceList' /* webpackChunkName: "PlayingSurfaceList" */
    ),
)

const PlayingSurfaceCreate = lazy(
  () =>
    import(
      '../PlayingSurfaceCreate' /* webpackChunkName: "PlayingSurfaceCreate" */
    ),
)

const PlayingSurfaceEdit = lazy(
  () =>
    import(
      '../PlayingSurfaceEdit' /* webpackChunkName: "PlayingSurfaceEdit" */
    ),
)

const Games = lazy(() => import('../Games' /* webpackChunkName: "Games" */))

const GameAwards = lazy(
  () => import('../GameAwards' /* webpackChunkName: "GameAwards" */),
)

const GameDetails = lazy(
  () => import('../GameDetails' /* webpackChunkName: "GameDetails" */),
)

const GamesIncidents = lazy(
  () => import('../GamesIncidents' /* webpackChunkName: "GameIncidents" */),
)

const GameIncidents = lazy(
  () => import('../GameIncidents' /* webpackChunkName: "GameIncidents" */),
)

const GameIncidentCreate = lazy(
  () =>
    import(
      '../GameIncidentCreate' /* webpackChunkName: "GameIncidentCreate" */
    ),
)

const GameIncidentEdit = lazy(
  () =>
    import('../GameIncidentEdit' /* webpackChunkName: "GameIncidentEdit" */),
)

const GameCoachesEdit = lazy(
  () => import('../GameCoachesEdit' /* webpackChunkName: "GameCoachesEdit" */),
)

const GameLineUpEdit = lazy(
  () => import('../GameLineUpEdit' /* webpackChunkName: "GameLineUpEdit" */),
)

const GameSelectTeam = lazy(
  () => import('../GameSelectTeam' /* webpackChunkName: "GameSelectTeam" */),
)

const GameTeamAwardVotes = lazy(
  () =>
    import(
      '../GameTeamAwardVotes' /* webpackChunkName: "GameTeamAwardVotes" */
    ),
)

const GameVolunteersEdit = lazy(
  () =>
    import(
      '../GameVolunteersEdit' /* webpackChunkName: "GameVolunteersEdit" */
    ),
)

const IncidentsGame = lazy(
  () => import('../IncidentsGame' /* webpackChunkName: "IncidentsGame" */),
)

const IncidentsNonGame = lazy(
  () =>
    import('../IncidentsNonGame' /* webpackChunkName: "IncidentsNonGame" */),
)

const NonGameIncident = lazy(
  () => import('../NonGameIncident' /* webpackChunkName: "NonGameIncident" */),
)

const NonGameIncidentCreate = lazy(
  () =>
    import(
      '../NonGameIncidentCreate' /* webpackChunkName: "NonGameIncidentCreate" */
    ),
)

const NonGameIncidentSearchMultistep = lazy(
  () =>
    import(
      '../NonGameIncidentSearchMultistep' /* webpackChunkName: "NonGameIncidentSearchMultistep" */
    ),
)

const NonGameIncidentCreateMultistep = lazy(
  () =>
    import(
      '../NonGameIncidentCreateMultistep' /* webpackChunkName: "NonGameIncidentCreateMultistep" */
    ),
)

const NonGameIncidentEdit = lazy(
  () =>
    import(
      '../NonGameIncidentEdit' /* webpackChunkName: "NonGameIncidentEdit" */
    ),
)

const IncidentOutcomeCreate = lazy(
  () =>
    import(
      '../IncidentOutcomeCreate' /* webpackChunkName: "IncidentOutcomeCreate" */
    ),
)

const IncidentOutcomeEdit = lazy(
  () =>
    import(
      '../IncidentOutcomeEdit' /* webpackChunkName: "IncidentOutcomeEdit" */
    ),
)

const Allocation = lazy(
  () => import('../Allocation' /* webpackChunkName: "Allocation" */),
)

const AllocationDateList = lazy(
  () =>
    import(
      '../AllocationDateList' /* webpackChunkName: "AllocationDateList" */
    ),
)

const AllocationSlotList = lazy(
  () =>
    import(
      '../AllocationSlotList' /* webpackChunkName: "AllocationSlotList" */
    ),
)

const AllocationDateEdit = lazy(
  () =>
    import(
      '../AllocationDateEdit' /* webpackChunkName: "AllocationDateEdit" */
    ),
)

const AllocationSlotEdit = lazy(
  () =>
    import(
      '../AllocationSlotEdit' /* webpackChunkName: "AllocationSlotEdit" */
    ),
)

const Regrade = lazy(
  () => import('../Regrade' /* webpackChunkName: "Regrade" */),
)

const Products = lazy(
  () => import('../Products' /* webpackChunkName: "Products" */),
)

const ProductList = lazy(
  () => import('../ProductList' /* webpackChunkName: "ProductList" */),
)

const ProductCreate = lazy(
  () => import('../ProductCreate' /* webpackChunkName: "ProductCreate" */),
)

const ProductEdit = lazy(
  () => import('../ProductEdit' /* webpackChunkName: "ProductEdit" */),
)

const OrderList = lazy(
  () => import('../OrderList' /* webpackChunkName: "OrderList" */),
)

const Order = lazy(() => import('../Order' /* webpackChunkName: "Order" */))

const OrderDetails = lazy(
  () => import('../OrderDetails' /* webpackChunkName: "OrderDetails" */),
)

const OrderShippingAddressEdit = lazy(
  () =>
    import(
      '../OrderShippingAddressEdit' /* webpackChunkName: "OrderShippingAddressEdit" */
    ),
)

const OrderCustomisationEdit = lazy(
  () =>
    import(
      '../OrderCustomisationEdit' /* webpackChunkName: "OrderCustomisationEdit" */
    ),
)

const ShopSettings = lazy(
  () => import('../ShopSettings' /* webpackChunkName: "ShopSettings" */),
)

const TransfersAndPermits = lazy(
  () =>
    import(
      '../TransfersAndPermits' /* webpackChunkName: "TransfersAndPermits" */
    ),
)

const TransfersList = lazy(
  () => import('../TransfersList' /* webpackChunkName: "TransfersList" */),
)

const PermitsList = lazy(
  () => import('../PermitsList' /* webpackChunkName: "PermitsList" */),
)

const TransfersAndPermitsSettings = lazy(
  () =>
    import(
      '../TransfersAndPermitsSettings' /* webpackChunkName: "TransfersAndPermitsSettings" */
    ),
)

const Transfer = lazy(
  () => import('../Transfer' /* webpackChunkName: "Transfer" */),
)

const Permit = lazy(() => import('../Permit' /* webpackChunkName: "Permit" */))

const PermitCreate = lazy(
  () => import('../PermitCreate' /* webpackChunkName: "TransferAndPermit" */),
)

const TransferCreate = lazy(
  () => import('../TransferCreate' /* webpackChunkName: "TransferCreate" */),
)

const Layout = styled.section`
  display: grid;
  grid-template-columns: 4rem auto;
  min-height: 100vh;
  overflow-y: hidden;

  ${media.desktop`
    grid-template-columns: 17.5rem auto;
  `}

  @media print {
    grid-template-columns: auto;
    min-height: 100%;
  }
`

const OrgRouter: React.FC<RouterProps> = props => {
  const org = useOrg()
  return !org ? <NotFound /> : <Router {...props} />
}

const Authenticated: React.FC<RouteComponentProps> = React.memo(
  ({ location }) => {
    const org = useOrg()
    const match = useMatch<{ organisationId: string }>('/org/:organisationId/*')
    const isClub = useOrgType(OrganisationType.Club)
    const isAdministrativeBodyOrg = useOrgType(
      OrganisationType.AdministrativeBody,
    )
    const { isRole: isSuperAdmin } = useRole('SUPER_ADMIN')
    const { isRole: isAdminBodyAdmin } = useRole('ADMIN_BODY')
    const isCaAdminAccountLinkingOn = useFeatureFlagOn(
      'ca-admin-account-linking',
    )
    const isShopOn = useFeatureFlagOn('shop', { organisationID: org?.id })
    const isFundraisingOn = useFeatureFlagOn('fundraising')
    const isPublicApiKeysOn = useFeatureFlagOn('public-api-keys')
    const isPermitsOn = useFeatureFlagOn('permits')
    const isStatisticsReportsOn = useFeatureFlagOn('statistics-reports')
    const isXeroOn = useFeatureFlagOn('xero')
    const isPostsOn = useFeatureFlagOn('posts')
    const { data: externalVouchersData, loading: externalVoucherLoading } =
      useTenantConfigurationHasExternalVouchersQuery()
    const { data: externalAccountData, loading: externalAccountLoading } =
      useLinkedExternalAccountQuery()

    const hasExternalVouchers =
      externalVouchersData?.tenantConfiguration.hasExternalVouchers
    const isAccountLinkingRequired =
      isCaAdminAccountLinkingOn &&
      externalAccountData?.tenantConfiguration.sport.name === 'cricket' &&
      externalAccountData?.tenantConfiguration.externalAccountLinking?.enabled
    const hasLinkedExternalAccount =
      !!externalAccountData?.account?.profile?.externalAccount
        ?.externalAccountID

    const transferEnabled =
      !externalAccountData?.tenantConfiguration?.transfers?.disabled

    const xeroCallbackUri = 'integrations/xero/connect'

    if (externalVoucherLoading || externalAccountLoading) {
      return null
    }

    if (isAccountLinkingRequired && !hasLinkedExternalAccount) {
      return (
        <AuthenticationBoundary
          onUnauthenticated={() => {
            navigate(
              `/auth/login${location ? `?redirect=${location.href}` : ''}`,
            )
          }}
        >
          <Global dark />
          <Suspense fallback={<Loader />}>
            {/* Redirect to '/external-account-linking' if required */}
            <Match path="/external-account-linking">
              {({ match }) => {
                return (
                  !match && <Redirect to="/external-account-linking" noThrow />
                )
              }}
            </Match>
            <Router>
              <ExternalAccountLinking path="external-account-linking" />
            </Router>
          </Suspense>
        </AuthenticationBoundary>
      )
    }

    return (
      <AuthenticationBoundary
        onUnauthenticated={() => {
          navigate(`/auth/login${location ? `?redirect=${location.href}` : ''}`)
        }}
      >
        <Layout>
          <Global />
          <Helmet titleTemplate="%s | PlayHQ" defaultTitle="PlayHQ" />

          <Navigation organisationId={match?.organisationId ?? ''} />

          <AuthorisationPageErrorBoundary>
            <PageErrorBoundary>
              <Suspense fallback={<Loader />}>
                {/* Redirect to the org you are in for legacy purposes */}
                <Match path="org/*">
                  {({ match, location: loc }) => {
                    return (
                      // NOTE: when setting up oAuth with Xero, the redirectUri
                      // needs to be whitelisted on their system. This means it
                      // needs to be a static URL, so we ignore the usual
                      // redirect to `/org/:orgId/*` here, and load the
                      // `organisationId` in the page from local storage.
                      (isXeroOn
                        ? loc.pathname !== `/${xeroCallbackUri}`
                        : true) &&
                      !match &&
                      org && (
                        <Redirect
                          to={`/org/${org.id}${loc.pathname}${loc.search}`}
                          noThrow
                        />
                      )
                    )
                  }}
                </Match>
                <OrgRouter
                  location={location}
                  primary={false}
                  component={React.Fragment}
                >
                  <Redirect
                    from="/org/:organisationId"
                    to="/org/:organisationId/competitions"
                    noThrow
                  />
                  {!isClub && (
                    <Competitions path="org/:organisationId/competitions">
                      <CompetitionList path="/" />
                    </Competitions>
                  )}
                  {isClub && (
                    <ClubCompetitions path="org/:organisationId/competitions" />
                  )}
                  {isClub && (
                    <ClubCompetitions path="org/:organisationId/competitions/invitations" />
                  )}
                  {!isClub && (
                    <CompetitionCreate path="org/:organisationId/competitions/create/*" />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/competitions/:competitionId"
                      to="/org/:organisationId/competitions/:competitionId/seasons"
                      noThrow
                    />
                  )}
                  {!isClub && (
                    <Competition path="org/:organisationId/competitions/:competitionId">
                      <SeasonList path="seasons" />
                      <CompetitionSettings path="settings">
                        <CompetitionSettingsGradeDefaults path="/:settingsSection/:gameTypeParam" />
                        <CompetitionSettingsGradeDefaults path="/:settingsSection" />
                        <CompetitionSettingsDetails path="/" />
                      </CompetitionSettings>
                    </Competition>
                  )}
                  {!isClub && (
                    <SeasonCreate path="org/:organisationId/competitions/:competitionId/seasons/create" />
                  )}
                  {!isClub && (
                    <SeasonRollover path="org/:organisationId/competitions/:competitionId/seasons/rollover/:seasonId/*" />
                  )}
                  <Redirect
                    from="/org/:organisationId/seasons/:seasonId"
                    to="/org/:organisationId/seasons/:seasonId/grades"
                    noThrow
                  />
                  <Season path="org/:organisationId/seasons/:seasonId">
                    <NotFound default />
                    <GradeList path="grades" />
                    <TeamList path="teams" />
                    <SeasonParticipants path="participants" />
                    {!isClub && (
                      <Registrations path="registrations">
                        <SeasonRegistrations path="association" />
                        <SeasonClubSettings path="clubs" />
                        <SeasonCustomFields path="custom-fields" />
                      </Registrations>
                    )}
                    {isClub && (
                      <Registrations path="registrations">
                        <SeasonRegistrations path="/" />
                        <SeasonAgeGroups path="age-groups" />
                        <SeasonCustomFields path="custom-fields" />
                      </Registrations>
                    )}

                    {!isClub && <SeasonSettings path="settings" />}
                    {!isClub && (
                      <Allocation path="allocations">
                        <AllocationSlotList path="slot" />
                        <AllocationDateList path="date" />
                      </Allocation>
                    )}

                    {!isClub && <SeasonFixturing path="fixturing" />}
                  </Season>

                  <SeasonPlayerPoints path="org/:organisationId/seasons/:seasonId/player-points" />
                  <SeasonOffenceList path="org/:organisationId/seasons/:seasonId/offence-list/*" />
                  {isClub && (
                    <SeasonAgeGroupDetails path="org/:organisationId/seasons/:seasonId/registrations/age-groups/:ageGroupValue" />
                  )}

                  {!isClub && (
                    <FixtureUpload path="org/:organisationId/seasons/:seasonId/fixturing/upload" />
                  )}
                  <CustomFieldCreate path="org/:organisationId/seasons/:seasonId/registrations/custom-fields/create" />
                  <CustomFieldCreate
                    path="org/:organisationId/seasons/:seasonId/registrations/custom-fields/team/create"
                    isTeamCustomField
                  />
                  <CustomFieldEdit path="org/:organisationId/seasons/:seasonId/registrations/custom-fields/:customFieldId/edit" />
                  {!isClub && (
                    <AllocationDateEdit path="org/:organisationId/seasons/:seasonId/allocations/date/:date/*" />
                  )}
                  {!isClub && (
                    <AllocationSlotEdit path="org/:organisationId/seasons/:seasonId/allocations/slot/:day/*" />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/seasons/:seasonId/allocations"
                      to="/org/:organisationId/seasons/:seasonId/allocations/date"
                      noThrow
                    />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/seasons/:seasonId/registrations/participant-to-association"
                      to="/org/:organisationId/seasons/:seasonId/registrations"
                      noThrow
                    />
                  )}
                  {!isClub && (
                    <ParticipantToOrganisationCreate path="org/:organisationId/seasons/:seasonId/registrations/participant-to-association/create/*" />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/seasons/:seasonId/registrations/participant-to-association/:registrationId"
                      to="/org/:organisationId/seasons/:seasonId/registrations/participant-to-association/:registrationId/edit"
                      noThrow
                    />
                  )}
                  {!isClub && (
                    <ParticipantToOrganisationEdit path="org/:organisationId/seasons/:seasonId/registrations/participant-to-association/:registrationId/edit/*" />
                  )}
                  {!isClub && (
                    <SocialTeamToSeasonCreate path="org/:organisationId/seasons/:seasonId/registrations/social-team-to-season/create/*" />
                  )}
                  {!isClub && (
                    <SocialTeamToSeasonEdit path="org/:organisationId/seasons/:seasonId/registrations/social-team-to-season/:registrationId/edit/*" />
                  )}
                  {!isClub && (
                    <ParticipantToTeamCreate path="org/:organisationId/seasons/:seasonId/registrations/participant-to-team/create/*" />
                  )}
                  {!isClub && (
                    <ParticipantToTeamEdit path="org/:organisationId/seasons/:seasonId/registrations/participant-to-team/:registrationId/edit/*" />
                  )}
                  {!isClub && (
                    <FamilyMemberDiscountCreate path="org/:organisationId/seasons/:seasonId/registrations/family-member-discount/create" />
                  )}
                  {!isClub && (
                    <FamilyMemberDiscountEdit path="org/:organisationId/seasons/:seasonId/registrations/family-member-discount/:discountId/edit" />
                  )}
                  {isClub && (
                    <ParticipantToOrganisationCreate
                      path="org/:organisationId/seasons/:seasonId/registrations/participant-to-club/create/*"
                      isClub
                    />
                  )}
                  {isClub && (
                    <ParticipantToOrganisationEdit
                      path="org/:organisationId/seasons/:seasonId/registrations/participant-to-club/:registrationId/edit/*"
                      isClub
                    />
                  )}
                  {isClub && (
                    <ParticipantToTeamCreate
                      path="org/:organisationId/seasons/:seasonId/registrations/participant-to-club-team/create/*"
                      isClub
                    />
                  )}
                  {isClub && (
                    <ParticipantToTeamEdit
                      path="org/:organisationId/seasons/:seasonId/registrations/participant-to-club-team/:registrationId/edit/*"
                      isClub
                    />
                  )}
                  {isClub && (
                    <FamilyMemberDiscountCreate
                      path="org/:organisationId/seasons/:seasonId/registrations/family-member-discount/create"
                      isClub
                    />
                  )}
                  {isClub && (
                    <FamilyMemberDiscountEdit
                      path="org/:organisationId/seasons/:seasonId/registrations/family-member-discount/:discountId/edit"
                      isClub
                    />
                  )}
                  {!isClub && (
                    <Regrade path="org/:organisationId/seasons/:seasonId/regrade/:filter" />
                  )}
                  {!isClub && (
                    <GradeCreate path="org/:organisationId/seasons/:seasonId/grades/create/*" />
                  )}
                  {!isClub && (
                    <GradeRollover path="org/:organisationId/seasons/:seasonId/grades/rollover/*" />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/seasons/:seasonId/grades/:gradeId/settings"
                      to="/org/:organisationId/seasons/:seasonId/grades/:gradeId/settings/general"
                      noThrow
                    />
                  )}
                  <AllLadders path="org/:organisationId/seasons/:seasonId/all-ladders" />
                  {!isClub && (
                    <RoundCreate path="org/:organisationId/seasons/:seasonId/grades/:gradeId/round/create" />
                  )}
                  {!isClub && (
                    <Grade path="org/:organisationId/seasons/:seasonId/grades/:gradeId">
                      <Fixture path="fixtures">
                        <FixtureRegular path="/" />
                        <FixtureFinals path="finals" />
                      </Fixture>
                      <GradeLadder path="ladder" />
                      <GradeSettings path="settings">
                        <GradeSettingsGeneral path="general" />
                        <GradeSettingsFixturing path="fixturing" />
                        <GradeSettingsLadder path="ladder" />
                        <GradeSettingsRegradeHistory path="regrades" />
                        <GradeSettingsGame path="/:settingsSection/:gameTypeParam" />
                        <GradeSettingsGame path="/:settingsSection" />
                      </GradeSettings>
                    </Grade>
                  )}
                  {!isClub && (
                    <GradeLadderAdjustment path="org/:organisationId/seasons/:seasonId/grades/:gradeId/ladder/adjustments" />
                  )}
                  {!isClub && (
                    <GradeExceptionDatesEdit path="org/:organisationId/seasons/:seasonId/grades/:gradeId/settings/fixturing/exception-dates" />
                  )}
                  {!isClub && (
                    <GradeExceptionDatesGenerate path="org/:organisationId/seasons/:seasonId/grades/:gradeId/settings/fixturing/exception-dates/generate" />
                  )}
                  {!isClub && (
                    <GradePools path="org/:organisationId/seasons/:seasonId/grades/:gradeId/pools" />
                  )}
                  {!isClub && (
                    <SeasonClubSettingsConfigure path="org/:organisationId/seasons/:seasonId/registrations/clubs/create" />
                  )}
                  {!isClub && (
                    <SeasonClubSettingsConfigure path="org/:organisationId/seasons/:seasonId/registrations/clubs/:clubSettingsId/edit" />
                  )}
                  {!isClub && (
                    <Redirect
                      from="/org/:organisationId/seasons/:seasonId/grades/:gradeId"
                      to="/org/:organisationId/seasons/:seasonId/grades/:gradeId/fixtures"
                      noThrow
                    />
                  )}
                  {!isClub && (
                    <GameEdit path="org/:organisationId/seasons/:seasonId/grades/:gradeId/games/:gameId" />
                  )}
                  {!isClub && (
                    <GameCreate path="org/:organisationId/seasons/:seasonId/grades/:gradeId/games/create" />
                  )}
                  {!isClub && (
                    <GameCreate
                      kind="finals"
                      path="org/:organisationId/seasons/:seasonId/grades/:gradeId/games/create/finals"
                    />
                  )}
                  {!isClub && (
                    <Game path="org/:organisationId/seasons/:seasonId/grades/:gradeId/games/:gameId/game-details/*" />
                  )}
                  {!isClub && (
                    <FixtureGenerate path="org/:organisationId/seasons/:seasonId/grades/:gradeId/generate-fixture/*" />
                  )}
                  {!isClub && (
                    <FixtureRoundSettingsReview path="org/:organisationId/seasons/:seasonId/grades/:gradeId/generate-fixture/round-settings/*" />
                  )}
                  {!isClub && (
                    <FixtureReview path="org/:organisationId/seasons/:seasonId/grades/:gradeId/review-fixture" />
                  )}
                  {!isClub && (
                    <FinalsGenerate path="org/:organisationId/seasons/:seasonId/grades/:gradeId/add-finals" />
                  )}
                  {!isClub && (
                    <FinalsRoundSettingsReview path="org/:organisationId/seasons/:seasonId/grades/:gradeId/add-finals/round-settings/*" />
                  )}
                  {!isClub && (
                    <FinalsReview path="org/:organisationId/seasons/:seasonId/grades/:gradeId/review-finals" />
                  )}
                  {!isClub && (
                    <FixtureGenerateHomeAndAway path="org/:organisationId/seasons/:seasonId/grades/:gradeId/generate-fixture-home-and-away/*" />
                  )}
                  <TeamCreate path="org/:organisationId/seasons/:seasonId/teams/create" />
                  <TeamRollover path="org/:organisationId/seasons/:seasonId/teams/rollover/*" />
                  {isClub && (
                    <SeasonAwardsConfigure path="org/:organisationId/seasons/:seasonId/teams/awards" />
                  )}
                  <Team path="org/:organisationId/seasons/:seasonId/teams/:teamId">
                    <TeamDetailList path="/" />
                    <TeamRegistrationsList path="/registrations" />
                    {isClub && <TeamAward path="/award" />}
                  </Team>
                  {isClub && (
                    <TeamAwardConfigure path="org/:organisationId/seasons/:seasonId/teams/:teamId/award/configure" />
                  )}
                  {!isClub && (
                    <TeamFinalsEligibilityOverride path="org/:organisationId/seasons/:seasonId/teams/:teamId/finals-eligibility-override" />
                  )}
                  <TeamGradeRestrictionsOverride path="org/:organisationId/seasons/:seasonId/teams/:teamId/grade-restrictions-override" />
                  <TeamCustomFieldsEdit path="org/:organisationId/seasons/:seasonId/teams/:teamId/registrations/:teamRegistrationId/custom-fields" />
                  <TeamEdit path="org/:organisationId/seasons/:seasonId/teams/:teamId/edit" />
                  <TeamPlayerNumbers path="org/:organisationId/seasons/:seasonId/teams/:teamId/player-numbers" />
                  {/* Game Day */}
                  <Games path="org/:organisationId/games" />
                  {!isClub && (
                    <>
                      <GamesIncidents path="org/:organisationId/games/incident-search" />
                      <GameIncidentEdit path="org/:organisationId/games/incident-search/:incidentId" />
                      <IncidentOutcomeCreate path="org/:organisationId/games/incident-search/:incidentId/outcome/create" />
                    </>
                  )}
                  <Game path="org/:organisationId/games/:gameId/*" />
                  {/* End Game Day */}

                  {/* Programs */}
                  <ProgramList path="org/:organisationId/programs" />
                  <ProgramCreate path="org/:organisationId/programs/create/*" />

                  <Redirect
                    from="/org/:organisationId/programs/:programId"
                    to="/org/:organisationId/programs/:programId/seasons"
                    noThrow
                  />
                  <Program path="org/:organisationId/programs/:programId">
                    <ProgramSeasonList path="seasons" />
                    <ProgramSettingsDetails path="settings" />
                  </Program>

                  <ProgramSeasonCreate path="org/:organisationId/programs/:programId/seasons/create" />

                  <Redirect
                    from="/org/:organisationId/program-seasons/:seasonId"
                    to="/org/:organisationId/program-seasons/:seasonId/registration/settings"
                    noThrow
                  />
                  <Redirect
                    from="/org/:organisationId/program-seasons/:seasonId/registration"
                    to="/org/:organisationId/program-seasons/:seasonId/registration/settings"
                    noThrow
                  />
                  <ProgramSeason path="org/:organisationId/program-seasons/:seasonId">
                    <ProgramSeasonOrganisations path="organisations" />
                    <ProgramSeasonSettings path="settings" />
                    <ProgramParticipantsList path="participants" />
                    <ProgramGroupsList path="groups" />
                    <ProgramRegistration path="registration">
                      <ProgramRegistrationSettings path="settings" />
                      <ProgramRegistrationLimits path="limits" />
                      <ProgramRegistrationCustomFields path="custom-fields" />
                    </ProgramRegistration>
                  </ProgramSeason>

                  <ProgramCustomFieldCreate path="org/:organisationId/program-seasons/:seasonId/registration/custom-fields/create" />
                  <ProgramCustomFieldEdit path="org/:organisationId/program-seasons/:seasonId/registration/custom-fields/:customFieldId/edit" />
                  <ParticipantToProgramCreate path="org/:organisationId/program-seasons/:seasonId/registration/participant-to-program/create/*" />
                  <ParticipantToProgramEdit path="org/:organisationId/program-seasons/:seasonId/registration/participant-to-program/:registrationId/edit/*" />
                  <OrganisationToProgramCreate path="org/:organisationId/program-seasons/:seasonId/registration/organisation-to-program/create/*" />
                  <OrganisationToProgramEdit path="org/:organisationId/program-seasons/:seasonId/registration/organisation-to-program/:registrationId/edit/*" />
                  <ProgramGroupCreate path="org/:organisationId/program-seasons/:seasonId/groups/create" />
                  <ProgramGroupEdit path="org/:organisationId/program-seasons/:seasonId/groups/:groupId/edit" />
                  <ProgramRegistrationLimitDetails path="org/:organisationId/program-seasons/:seasonId/registration/limits/:groupId" />

                  <ProgramGroup path="org/:organisationId/program-seasons/:seasonId/groups/:groupId" />
                  {/* End Programs */}

                  {/* Participants */}
                  <ParticipantsSearch path="org/:organisationId/participants" />
                  <ParticipantRoutes path="org/:organisationId/participants/:profileId/*" />
                  {/* End Participants */}

                  {/* Incidents */}
                  {!isClub && (
                    <>
                      <IncidentsGame path="org/:organisationId/incidents/game" />
                      <GameIncidentEdit path="org/:organisationId/incidents/game/:incidentId" />
                      <IncidentOutcomeCreate path="org/:organisationId/incidents/game/:incidentId/outcome/create" />
                      {/* Start Non-Game Participants Incidents Route */}
                      <IncidentsNonGame path="org/:organisationId/incidents/non-game" />
                      <NonGameIncident path="org/:organisationId/incidents/non-game/:incidentId" />
                      <NonGameIncidentEdit path="org/:organisationId/incidents/non-game/:incidentId/edit" />
                      <NonGameIncidentSearchMultistep path="org/:organisationId/incidents/non-game/create/search" />
                      <NonGameIncidentCreateMultistep path="org/:organisationId/incidents/non-game/create/details" />
                      <IncidentOutcomeCreate path="org/:organisationId/incidents/non-game/:incidentId/outcome/create" />
                      <IncidentOutcomeEdit path="org/:organisationId/incidents/non-game/:incidentId/outcome/edit" />
                      {/* End Non-Game Participants Incidents Route */}
                    </>
                  )}
                  {/* End Incidents */}

                  {/* Transfers and Permits */}
                  <TransfersAndPermits
                    default={false}
                    path="org/:organisationId/transfers-and-permits"
                  >
                    <NotFound default />
                    <Redirect from="/" to="transfers" noThrow />
                    <TransfersList path="/transfers" />
                    {isPermitsOn && <PermitsList path="/permits" />}
                    <TransfersAndPermitsSettings path="/settings" />
                  </TransfersAndPermits>
                  {!transferEnabled && isPermitsOn && (
                    <Redirect
                      from="/org/:organisationId/transfers-and-permits"
                      to="/org/:organisationId/transfers-and-permits/permits"
                      noThrow
                    />
                  )}

                  {transferEnabled && (
                    <Redirect
                      from="/org/:organisationId/transfers-and-permits"
                      to="/org/:organisationId/transfers-and-permits/transfers"
                      noThrow
                    />
                  )}
                  <Transfer path="org/:organisationId/transfers-and-permits/transfer/:transferId" />
                  <Permit path="org/:organisationId/transfers-and-permits/permit/:permitId" />
                  {isClub && (
                    <PermitCreate path="org/:organisationId/transfers-and-permits/create/permit/*" />
                  )}
                  {isClub && (
                    <TransferCreate path="org/:organisationId/transfers-and-permits/create/transfer/*" />
                  )}
                  {/* End Transfers and Permits */}

                  {isFundraisingOn && (
                    <>
                      <Redirect
                        from="/org/:organisationId/products"
                        to="/org/:organisationId/products/merchandise"
                        noThrow
                      />
                      <Redirect
                        from="/org/:organisationId/product-orders"
                        to="/org/:organisationId/products/orders"
                        noThrow
                      />
                      <Products path="org/:organisationId/products">
                        <ProductList path="/:productType" />
                        <OrderList path="orders" />
                        {isShopOn && <ShopSettings path="shop-settings" />}
                      </Products>

                      <ProductCreate path="org/:organisationId/products/:productType/create/*" />
                      <ProductEdit path="org/:organisationId/products/:productType/:productId/*" />
                      <Order path="org/:organisationId/products/orders/:orderId">
                        <OrderDetails path="details" />
                      </Order>
                      <OrderShippingAddressEdit path="org/:organisationId/products/orders/:orderId/shipping-address" />
                      <OrderCustomisationEdit path="org/:organisationId/products/orders/:orderId/customisation" />
                    </>
                  )}

                  {!isFundraisingOn && (
                    <>
                      {/* Products */}
                      <Products
                        path="org/:organisationId/products"
                        showTabs={false}
                      >
                        <ProductList path="/" />
                      </Products>
                      <ProductCreate path="org/:organisationId/products/create/*" />
                      <ProductEdit path="org/:organisationId/products/:productId/*" />
                      {/* End Products */}

                      {/* Orders */}
                      <OrderList path="org/:organisationId/product-orders" />
                      <Redirect
                        from="/org/:organisationId/product-orders/:orderId"
                        to="/org/:organisationId/product-orders/:orderId/details"
                        noThrow
                      />
                      <Order path="org/:organisationId/product-orders/:orderId">
                        <OrderDetails path="details" />
                      </Order>

                      <OrderShippingAddressEdit path="org/:organisationId/product-orders/:orderId/shipping-address" />
                      <OrderCustomisationEdit path="org/:organisationId/product-orders/:orderId/customisation" />
                      {/* End Orders */}
                    </>
                  )}

                  {/* My Organisation */}
                  <Redirect
                    from="/org/:organisationId/my-organisation"
                    to="/org/:organisationId/my-organisation/overview"
                    noThrow
                  />
                  <MyOrganisationOverview path="org/:organisationId/my-organisation/overview">
                    <MyOrganisationDetails path="/" />
                    <MyOrganisationContacts path="contacts" />
                    <MyOrganisationNotifications path="notifications" />
                    <MyOrganisationVouchers path="vouchers" />
                    <MyOrganisationMemberships path="membership" />
                    <MyOrganisationPayment path="payments" />
                    <MyOrganisationSponsorshipAdvertising path="sponsorship-advertising/*" />
                  </MyOrganisationOverview>
                  <MyOrganisationBankAccountEdit path="org/:organisationId/my-organisation/overview/payments/:bankAccountId/edit" />
                  <>
                    <RoleMembershipCreate path="org/:organisationId/my-organisation/overview/membership/create" />
                    <RoleMembershipEdit path="org/:organisationId/my-organisation/overview/membership/:membershipId/edit" />
                  </>
                  <SubmitPaymentDetails path="org/:organisationId/my-organisation/overview/payments/details" />
                  <VoucherCreate path="org/:organisationId/my-organisation/overview/vouchers/create" />
                  <VoucherEdit path="org/:organisationId/my-organisation/overview/vouchers/:voucherType/:voucherId" />
                  {/* Admins */}
                  <AdminEdit path="org/:organisationId/my-organisation/admins/:accountId/edit" />
                  <AdminInviteCreate path="org/:organisationId/my-organisation/admins/create" />
                  <Admins path="org/:organisationId/my-organisation/admins">
                    <AdminList path="/" />
                    <AdminAccessHistoryList path="history" />
                  </Admins>
                  {/* End Admins */}
                  {/* Settings */}

                  <Settings path="org/:organisationId/settings" />
                  <Redirect
                    from="/org/:organisationId/settings/general"
                    to={
                      hasExternalVouchers
                        ? '/org/:organisationId/settings/general/vouchers'
                        : '/org/:organisationId/settings/general/integrations'
                    }
                    noThrow
                  />
                  <SettingsGeneral path="org/:organisationId/settings/general">
                    {hasExternalVouchers && (
                      <SettingsVouchers path="/vouchers" />
                    )}
                    <SettingsIntegrations path="/integrations" />
                    {isAdministrativeBodyOrg && (
                      <AdminBodyCustomFields path="/admin-body-custom-fields" />
                    )}
                    {isAdministrativeBodyOrg && (
                      <AdminBodyCustomFields path="/admin-body-custom-fields/:seasonName" />
                    )}
                    <SettingsCodesOfConduct path="/codes-of-conduct" />
                    <SettingsPermits path="/permits">
                      <SeasonPermitSettings path="/" />
                      <PermitAgreementList path="agreements" />
                    </SettingsPermits>
                    <SettingsTransfers path="/transfers">
                      <TransferExceptionPeriodList path="/" />
                    </SettingsTransfers>
                  </SettingsGeneral>
                  <TransferExceptionPeriodCreate path="org/:organisationId/settings/general/transfers/create" />
                  <TransferExceptionPeriodEdit path="org/:organisationId/settings/general/transfers/:transferExceptionPeriodId" />
                  <PermitAgreementCreate path="org/:organisationId/settings/general/permits/agreements/create" />
                  <PermitAgreementEdit path="org/:organisationId/settings/general/permits/agreements/:permitId" />
                  {isAdministrativeBodyOrg && (
                    <AdminBodyCustomFieldsCreate path="org/:organisationId/settings/general/admin-body-custom-fields/:seasonName/create" />
                  )}
                  {isAdministrativeBodyOrg && (
                    <AdminBodyCustomFieldsEdit path="org/:organisationId/settings/general/admin-body-custom-fields/:seasonName/:customFieldId/edit" />
                  )}

                  {isSuperAdmin && isAdministrativeBodyOrg && (
                    <CodeOfConductCreate path="org/:organisationId/settings/general/codes-of-conduct/create" />
                  )}
                  {isSuperAdmin && isAdministrativeBodyOrg && (
                    <CodeOfConductEdit path="org/:organisationId/settings/general/codes-of-conduct/:codeOfConductId/edit" />
                  )}
                  <MailchimpIntegration path="org/:organisationId/settings/general/integrations/mailchimp" />
                  <MailchimpConnect path="org/:organisationId/settings/general/integrations/mailchimp/connect" />
                  {isXeroOn && (
                    <XeroIntegration path="org/:organisationId/settings/general/integrations/xero" />
                  )}
                  {isXeroOn && <XeroConnect path={xeroCallbackUri} />}
                  {/* End Settings */}
                  {/* Organisations */}
                  <Organisations path="org/:organisationId/settings/organisations">
                    <OrganisationList path="/" />
                  </Organisations>
                  <Redirect
                    from="/org/:organisationId/organisations"
                    to="/org/:organisationId/settings/organisations"
                    noThrow
                  />
                  {(isSuperAdmin || isAdminBodyAdmin) && (
                    <Redirect
                      from="/org/:organisationId/settings/organisations/:orgSettingsId"
                      to="/org/:organisationId/settings/organisations/:orgSettingsId/settings"
                      noThrow
                    />
                  )}
                  {(isSuperAdmin || isAdminBodyAdmin) && (
                    <Organisation path="org/:organisationId/settings/organisations/:orgSettingsId">
                      <OrganisationSettings path="settings" />
                      {isSuperAdmin && isPublicApiKeysOn && (
                        <OrganisationApiKeys path="api-keys" />
                      )}
                    </Organisation>
                  )}
                  {isSuperAdmin && isPublicApiKeysOn && (
                    <OrganisationApiKeyCreate path="org/:organisationId/settings/organisations/:orgSettingsId/api-keys/create" />
                  )}
                  {isSuperAdmin && (
                    <OrganisationCreate path="org/:organisationId/settings/organisations/create" />
                  )}
                  {/* End Organisations */}
                  {/* Directory */}
                  <Venues path="org/:organisationId/venues">
                    <VenueList path="/" />
                  </Venues>
                  {isSuperAdmin && (
                    <VenueCreate path="org/:organisationId/venues/create" />
                  )}
                  <Redirect
                    from="/org/:organisationId/venues/:venueId"
                    to="/org/:organisationId/venues/:venueId/playing-surfaces"
                    noThrow
                  />
                  {/* Redirect from courts to playing surfaces */}
                  <Redirect
                    from="/org/:organisationId/venues/:venueId/courts"
                    to="/org/:organisationId/venues/:venueId/playing-surfaces"
                    noThrow
                  />
                  <Venue path="org/:organisationId/venues/:venueId">
                    <PlayingSurfaceList path="playing-surfaces" />
                  </Venue>
                  {isSuperAdmin && (
                    <VenueEdit path="org/:organisationId/venues/:venueId/edit" />
                  )}
                  {isSuperAdmin && (
                    <PlayingSurfaceCreate path="org/:organisationId/venues/:venueId/playing-surfaces/create" />
                  )}
                  {isSuperAdmin && (
                    <PlayingSurfaceEdit path="org/:organisationId/venues/:venueId/playing-surfaces/:playingSurfaceId/edit" />
                  )}
                  {/* End Directory */}
                  <AccountOverview path="org/:organisationId/account">
                    <AccountDetails path="/" />
                    <AccountSecurity path="security" />
                  </AccountOverview>
                  <MfaSetup path="org/:organisationId/account/security/add-mfa" />
                  <Redirect
                    from="/org/:organisationId/reports"
                    to="/org/:organisationId/reports/competitions"
                    noThrow
                  />
                  <Reports path="org/:organisationId/reports">
                    <ReportsCompetitions path="competitions" />
                    {isStatisticsReportsOn && (
                      <ReportsStatistics path="statistics" />
                    )}
                    <ReportsPrograms path="programs" />
                    <ReportsFinancial path="financial" />
                    <ReportsOrders path="orders" />
                    <ReportsDiscipline path="discipline" />
                    <ReportsTransfers path="transfers" />
                    <ReportsGenerated path="generated-reports" />
                  </Reports>
                  <ReportsCompetitionsCustomise path="org/:organisationId/reports/competitions/:type/customise" />
                  <ReportsFinancialCustomise path="org/:organisationId/reports/financial/:type/customise" />
                  <ReportsProgramParticipantCustomise path="org/:organisationId/reports/programs/:type/customise" />
                  {isPostsOn && (
                    <>
                      <Posts path="org/:organisationId/posts" />
                      <PostCreate path="org/:organisationId/posts/create" />
                      <PostEdit path="org/:organisationId/posts/:postId" />
                    </>
                  )}

                  <NotFound default />
                </OrgRouter>
              </Suspense>
            </PageErrorBoundary>
          </AuthorisationPageErrorBoundary>
        </Layout>
      </AuthenticationBoundary>
    )
  },
  (prevProps, nextProps) =>
    Boolean(
      prevProps.location &&
        nextProps.location &&
        prevProps.location.href === nextProps.location.href &&
        isEqual(prevProps.location.state, nextProps.location.state),
    ),
)

const Security: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Global dark />
      <Helmet titleTemplate="%s | PlayHQ" defaultTitle="PlayHQ" />
      <PageErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Router>
            <MfaSetupRequired path="add-mfa" />
          </Router>
        </Suspense>
      </PageErrorBoundary>
    </>
  )
}

const Unauthenticated: React.FC<RouteComponentProps> = () => {
  const isLayupKillLoginOn = useFeatureFlagOn('kill-login')

  return (
    <>
      <Global dark />
      <Helmet titleTemplate="%s | PlayHQ" defaultTitle="PlayHQ" />
      <PageErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Router>
            {isLayupKillLoginOn ? (
              <>
                <Maintenance path="login" />
                <Maintenance path="signup/:token" />
                <Maintenance path="/forgot-password" />
                <Maintenance path="reset-password/:token" />
              </>
            ) : (
              <>
                <Login path="login" />
                <Signup path="signup/:token" />
                <ForgotPassword path="/forgot-password" />
                <ResetPassword path="reset-password/:token" />
              </>
            )}
            <Logout path="logout" />
          </Router>
        </Suspense>
      </PageErrorBoundary>
    </>
  )
}

const Generate: React.FC<RouteComponentProps> = () => (
  <Suspense fallback={<Loader />}>
    <Router primary={false}>
      <ReportsGenerate path="reports">
        <ReportVenue path="venue" />
        <ReportTeam path="team" />
      </ReportsGenerate>
    </Router>
  </Suspense>
)

const Game: React.FC<RouteComponentProps> = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router primary={false} component={React.Fragment}>
        <GameDetails path="/" />
        <GameLineUpEdit path="/teams/:teamId/line-up" />
        <GameCoachesEdit path="/teams/:teamId/coaches" />
        <GameVolunteersEdit path="/teams/:teamId/volunteers" />
        <GameSelectTeam path="/teams/:teamId/select-team" />
        <GameIncidents path="/incidents" />
        <GameIncidentCreate path="/incidents/create" />
        <GameIncidentEdit path="/incidents/:incidentId" />
        <IncidentOutcomeCreate path="/incidents/:incidentId/outcome/create" />
        <GameAwards path="/awards" />
        <GameTeamAwardVotes path="/awards/teams/:teamId" />
      </Router>
    </Suspense>
  )
}

const ParticipantRoutes: React.FC<
  RouteComponentProps<{
    location: WindowLocation<{ returnPath?: string } | null>
  }>
> = ({ location }) => {
  // This preserves the back link when navigating within the participant routes
  const returnPath = useRef(location?.state?.returnPath).current
  useEffect(() => {
    if (!location?.state?.returnPath && returnPath) {
      navigate('', { state: { ...location?.state, returnPath }, replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.returnPath])

  const { isRole: isSuperAdmin } = useRole('SUPER_ADMIN')
  const { isRole: isAdminBodyAdmin } = useRole('ADMIN_BODY')
  const { isRole: isAffiliateAdmin } = useRole('AFFILIATE_ADMIN')

  return (
    <Suspense fallback={<Loader />}>
      <Router primary={false} component={React.Fragment}>
        <Participant path="/*" returnPath={returnPath} />
        <ParticipantEditDetails path="/edit-details" />
        <ParticipantDuplicateComparison path="/duplicate-comparison/:duplicateId" />
        <ParticipantDuplicateManagement path="/duplicate-management" />
        {(isSuperAdmin || isAdminBodyAdmin || isAffiliateAdmin) && (
          <ParticipantRevertAffiliation path="/revert-affiliation" />
        )}
        <ParticipantCustomFieldsEdit path="/:participantId/custom-fields" />
        <ParticipantProgramTransfer path="/:participantId/:seasonId/transfer" />

        {/* Start Non-Game Participants Route */}
        <NonGameIncidentCreate path="/incidents/non-game/create" />
        <NonGameIncident
          routeVariant="participants"
          path="/incidents/non-game/:incidentId"
        />
        <NonGameIncidentEdit
          routeVariant="participants"
          path="/incidents/non-game/:incidentId/edit"
        />
        <IncidentOutcomeCreate path="/incidents/non-game/:incidentId/outcome/create" />
        <IncidentOutcomeEdit path="/incidents/non-game/:incidentId/outcome/edit" />
        {/* End Non-Game Participants Route */}
      </Router>
    </Suspense>
  )
}

declare global {
  interface Window {
    Appcues: any
  }
}

const App = () => {
  const isSecurityRoute = useMatch('security/*')
  const isUnauthenticatedRoute = useMatch('auth/*')
  const skipInitialiseQueries = !!isSecurityRoute || !!isUnauthenticatedRoute
  return (
    <>
      <Location>
        {({ location }) => {
          // Have to check if it exists as ad blockers will block it
          if (window.Appcues) {
            window.Appcues.page()
          }

          return (
            <AppPreloader
              location={location}
              skipInitialiseQueries={skipInitialiseQueries}
            >
              <Router>
                <Generate path="generate/*" />
                <Security path="security/*" />
                <Unauthenticated path="auth/*" />
                <Authenticated path="*" />
                <NotFound default />
              </Router>
            </AppPreloader>
          )
        }}
      </Location>
      <Suspense fallback={<Loader />}>
        <Icons />
      </Suspense>
    </>
  )
}

export default App
