import { WindowLocation, navigate } from '@reach/router'
import omitBy from 'lodash/fp/omitBy'

export type LocationParams = Record<string, string>

export const getLocationParams: (
  location: WindowLocation,
) => LocationParams = location => {
  const params =
    (location.search?.[0] === '?' ? location.search.slice(1) : location.search)
      ?.split('&')
      .filter(Boolean) ?? []

  return params.reduce((paramsObj: LocationParams, param: string) => {
    const separatorIndex = param.indexOf('=')
    const currentParam = {
      name: param.slice(0, separatorIndex),
      value: decodeURIComponent(param.slice(separatorIndex + 1)),
    }

    paramsObj[currentParam.name] = currentParam.value
    return paramsObj
  }, {} as LocationParams)
}

export const getLocationParamsV2 = (
  location: WindowLocation,
): LocationParams => {
  const searchParams = new URLSearchParams(location.search)
  const result: LocationParams = {}

  for (const [key, value] of searchParams) {
    result[key] = decodeURIComponent(value)
  }

  return result
}

export const encodeLocationParams = (params: {
  [key: string]: string | undefined
}): string => {
  const newParams = Object.entries(params)
    .map(([key, value]) => {
      const encodedValue =
        typeof value !== 'undefined' ? encodeURIComponent(value) : undefined
      return !!key && typeof encodedValue !== 'undefined'
        ? [key, encodedValue].join('=')
        : undefined
    })
    .filter(param => !!param)
    .join('&')

  return newParams ? `?${newParams}` : ''
}

// _.isEmpty wont work for numbers: https://github.com/lodash/lodash/issues/483
export const isEmpty = (value?: any): boolean => {
  if (value === undefined) return true
  if (value === null) return true

  if (typeof value === 'string') {
    return value.trim() === ''
  }

  if (Array.isArray(value)) {
    return value.length === 0
  }

  return false
}

export const withoutEmptyValues = omitBy(isEmpty)

export const isValidRedirect = (
  redirectTo: string,
  origin: string,
): boolean => {
  return (
    RegExp(/^\/[a-z-/]+$/).test(redirectTo) ||
    redirectTo?.startsWith(`${origin}/`)
  )
}

export const getFullPath = (location: WindowLocation): string => {
  const search =
    location.search?.[0] === '?' ? location.search.slice(1) : location.search
  return location.pathname + (search ? `?${search}` : '')
}

export const createSetUrlParams =
  <T>(location: WindowLocation, locationParams: T) =>
  (newParams: T) =>
    navigate(
      `${location?.pathname}${encodeLocationParams(
        withoutEmptyValues({
          ...locationParams,
          ...newParams,
          page: undefined,
        }),
      )}`,
    )
