import React from 'react'
import { createGlobalStyle } from 'styled-components'
import theme from '../../theme'
import { media } from '../../utils/styled-components-utils'

interface GlobalProps {
  theme?: typeof theme
  dark?: boolean
}

const GlobalStyle = createGlobalStyle<GlobalProps>`
  html {
    box-sizing: border-box;
    text-size-adjust: 100%;
    font-size: 0.75rem;

    ${media.largeDesktop`
      font-size: 1rem;
    `}
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Karla, sans-serif;
    line-height: 1.375;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.theme.secondary};
    background-color: ${props =>
      props.dark ? props.theme.black400 : props.theme.bodyBackground};

    *, *::before, *::after {
      box-sizing: border-box;

      &:focus {
        outline-color: ${props => props.theme.primary};
      }
    }

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }

    button {
      font-family: Karla, sans-serif;
    }

    legend {
      padding: 0;
    }

    .__react_component_tooltip {
      font-size: 0.812rem;
      z-index: 1024;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
  }

  @media print {
    * {
      /* stylelint-disable-next-line declaration-no-important */
      overflow: visible !important;
    }
  }

  .appcues-widget-icon {
    color: #858698;
    margin-left: -0.5rem;
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 2rem !important;
    pointer-events: none
  }
  
  .react-tooltip {
      z-index: 9;
  }
`

const Global: React.FunctionComponent<GlobalProps> = ({ dark }) => (
  <>
    <GlobalStyle dark={dark} />
  </>
)

export default Global
