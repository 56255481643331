import { Attributes } from '@splitsoftware/splitio/types/splitio'
import React from 'react'
import { FeatureFlag as FeatureFlagName } from '../../flags'
import useFeatureFlag from '../../hooks/useFeatureFlag'

interface FeatureFlagProps {
  attributes?: Attributes
  name: FeatureFlagName
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({
  attributes,
  name,
  children,
}) => {
  const [treatment] = useFeatureFlag(name, attributes)
  return <>{treatment === 'on' && children}</>
}

export default FeatureFlag
