import { useTransfersDisabledTenantConfigQuery } from '../generated/graphql'

const useTransfersDisabled = (): boolean | undefined => {
  const { data } = useTransfersDisabledTenantConfigQuery({
    fetchPolicy: 'cache-first',
  })

  return data?.tenantConfiguration.transfers.disabled ?? undefined
}

export default useTransfersDisabled
