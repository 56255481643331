import AsyncRemove from '@gameonsports/components/lib/AsyncRemove'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Toast, ToastType } from '../Toast'

interface ToastProviderType {
  addToast: (toast: ToastType) => void
}

export const ToastProvider = React.createContext<ToastProviderType>({
  addToast: () => {},
})

const ToastContainer = styled.section`
  position: fixed;
  bottom: 1rem;
  left: 0;
  width: 100%;

  display: grid;
  grid-gap: 1rem;
  z-index: 999;
`

const DEFAULT_TIMEOUT = 10000

export const Toaster: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastType[]>([])

  const addToast = (toast: ToastType) => {
    setToasts(toasts => {
      if (toast.id && toasts.some(t => t.id === toast.id)) {
        return toasts
      }

      return toasts.concat({
        id: Math.random().toString(32).slice(2),
        ...toast,
      })
    })

    setTimeout(() => {
      setToasts(toasts.filter(t => t.id !== toast.id))
    }, (toast.timeout || DEFAULT_TIMEOUT) + 1000)
  }

  return (
    <ToastProvider.Provider value={{ addToast }}>
      {children}
      {toasts.length > 0 && (
        <ToastContainer>
          {toasts.map((toast, index) => (
            <AsyncRemove
              key={`toast-${toast.id || index}`}
              delay={toast.timeout || DEFAULT_TIMEOUT}
            >
              <Toast
                {...toast}
                onConfirm={
                  toast.onConfirm
                    ? () => {
                        toast.onConfirm!()
                        setToasts(toasts.filter(t => t.id !== toast.id))
                      }
                    : undefined
                }
                onCancel={
                  toast.onCancel
                    ? () => {
                        toast.onCancel!()
                        setToasts(toasts.filter(t => t.id !== toast.id))
                      }
                    : undefined
                }
              />
            </AsyncRemove>
          ))}
        </ToastContainer>
      )}
    </ToastProvider.Provider>
  )
}
