const localStorageKeys = {
  hideClubMembershipsNewDot: 'hideClubMembershipsNewDot',
  hideFundraisingNewDot: 'hideFundraisingNewDot',
  hideProductsNewPill: 'hideProductsNewPill',
  hideReportsNewPill: 'hideReportsNewPill-v2',
  hideCompetitionsNewDot: 'hideCompetitionsNewDot-v2',
  hideCompReportCustomisationNewPill: 'hideCompReportCustomisationNewPill-v2',
  hideFinancialReportsNewDot: 'hideFinancialReportsNewDot',
  hideTransactionCustomisationNewPill: 'hideTransactionCustomisationNewPill',
  hideTransactionDateFilterNewPill: 'hideTransactionDateFilterNewPill',
  hideProgramReportsNewDot: 'hideProgramReportsNewDot',
  hideProgramReportsCustomisationNewPill:
    'hideProgramReportsCustomisationNewPill',
  hidePostsNewPill: 'hidePostsNewPill',
} as const

export default localStorageKeys
