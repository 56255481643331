import theme, { colors } from '@gameonsports/components/lib/theme'

const adminPortalColorOverrides = {
  primary: colors.pineapple400,
  secondary: colors.black400,
  tertiary: colors.lightGrey400,
  bodyBackground: colors.grey400,

  // Button
  buttonPrimary: theme.buttonSenary,
  buttonPrimaryHover: theme.buttonSenaryHover,
  buttonPrimaryColor: theme.buttonSenaryColor,
  buttonPrimaryHoverColor: theme.buttonSenaryHoverColor,
  buttonSecondary: theme.buttonSeptenary,
  buttonSecondaryHover: theme.buttonSeptenaryHover,
  buttonSecondaryColor: theme.buttonSeptenaryColor,
  buttonSecondaryHoverColor: theme.buttonSeptenaryHoverColor,
  buttonTertiary: theme.buttonQuinary,
  buttonTertiaryHover: theme.buttonQuinaryHover,
  buttonTertiaryColor: theme.buttonQuinaryColor,
  buttonTertiaryHoverColor: theme.buttonQuinaryHoverColor,
  buttonQuinary: theme.buttonTertiary,
  buttonQuinaryHover: theme.buttonTertiaryHover,
  buttonQuinaryColor: theme.buttonTertiaryColor,
  buttonQuinaryHoverColor: theme.buttonTertiaryHoverColor,
  buttonSenary: theme.buttonPrimary,
  buttonSenaryHover: theme.buttonPrimaryHover,
  buttonSenaryColor: theme.buttonPrimaryColor,
  buttonSenaryHoverColor: theme.buttonPrimaryHoverColor,
  buttonSeptenary: theme.buttonSecondary,
  buttonSeptenaryHover: theme.buttonSecondaryHover,
  buttonSeptenaryColor: theme.buttonSecondaryColor,
  buttonSeptenaryHoverColor: theme.buttonSecondaryHoverColor,
  buttonWhite: colors.white400,
  buttonWhiteHover: colors.blackberry200,
  buttonWhiteColor: colors.black400,
  buttonTextColor: colors.black400,
  buttonError: colors.cherry400,
  buttonErrorHover: colors.black400,
  buttonErrorColor: colors.white400,
  buttonErrorHoverColor: colors.white400,
}

const adminPortalThemeOverride = {
  inputBorderRadius: '0',
  buttonFontWeight: '400',

  // Mobile Padding
  buttonPadding: '0.75rem 1rem',
  buttonPaddingSmall: '0.5rem',

  // Tablet+ padding
  buttonPaddingTablet: '0.75rem 1rem',
  buttonPaddingTabletSmall: '0.5rem',

  fontFamily: 'Karla, sans-serif',
}

const adminPortalTheme = {
  ...theme,
  ...adminPortalColorOverrides,
  ...adminPortalThemeOverride,
}

export default adminPortalTheme
