import { useTreatments } from '@splitsoftware/splitio-react'
import merge from 'lodash/merge'
import { FeatureFlag } from '../flags'
import useMemoizedEqualObject from './useMemoizedEqualObject'
import useOrg from './useOrg'

export type SplitAttributes = 'organisationID'

export type SplitAttributeTypes = string | number | undefined | null

export default (
  name: FeatureFlag,
  attributes: Partial<Record<SplitAttributes, SplitAttributeTypes>> = {},
) => {
  const orgData = useOrg()
  const memoizedData = useMemoizedEqualObject(
    merge({}, {}, attributes, {
      tenant: process.env.REACT_APP_TENANT_SLUG,
      ...(orgData?.id ? { organisationID: orgData.id } : undefined),
    }),
  )

  const treatments = useTreatments([name], memoizedData)
  const treatmentWithConfig = treatments[name]
  return [treatmentWithConfig.treatment, treatmentWithConfig.config]
}
