/**
 * Example feature flag entry
 *
 * 'example-feature-flag': {
 *    squad: 'winners', // squad name
 *    ticketNo: 'GO-28991' // Link to Jira ticket of the feature that uses this flag or a description, e.g. https://gameonsport.atlassian.net/browse/GO-25136
 *    lastUpdatedDate: '1 Jan 2024' // Date the feature flag is created or updated, aim to clean up after a month in prod. Valid date string when put into new Date() function
 *    longLived: true, // Set to true if this feature flag cannot be removed safely and does not have a planned removal task (not recommended)
 *  },
 */

export type FeatureFlag =
  | 'additional-auto-approval-settings'
  | 'allocation-clash-dot'
  | 'all-play-grades'
  | 'batter-grace-period'
  | 'best-players'
  | 'bonus-points'
  | 'bulk-season-permits'
  | 'bulk-season-permits-season-restriction'
  | 'ca-admin-account-linking'
  | 'career-club-statistics-report'
  | 'competition-teams-list-filters-sorting'
  | 'cricket-fielding-statistics'
  | 'environment-labels'
  | 'fundraising'
  | 'fundraising-new-prompts'
  | 'financial-reports-enhancement'
  | 'fixture-from-round-number'
  | 'fixture-round-list-v2'
  | 'game-day-actions-menu'
  | 'game-payments'
  | 'game-state-indicators'
  | 'government-vouchers-report-enhancement'
  | 'gr4vy-embed-checkout'
  | 'grades-sorting'
  | 'inbound-participant-permit-setting'
  | 'in-game-positions'
  | 'kill-login'
  | 'live-game-scorecard'
  | 'live-scored-lineup-locked'
  | 'live-scored-club-locked'
  | 'live-scoring'
  | 'live-streaming-enabled'
  | 'memberships'
  | 'memberships-new-prompts'
  | 'multi-factor-authentication-setup'
  | 'offence-list'
  | 'order-list-optimisation'
  | 'participant-reports-customisation'
  | 'participant-reports-filters'
  | 'participant-reports-season-multiselect'
  | 'participant-reports-templates'
  | 'participant-reports-unique'
  | 'participant-details-external-accounts'
  | 'permit-preference-settings'
  | 'permits'
  | 'permits-commentary-box'
  | 'permits-list-to-from-dates'
  | 'posts'
  | 'product-payment-requests'
  | 'program-search-filters-sorting'
  | 'program-season-enhancements'
  | 'program-shop-link'
  | 'program-reports-customisation'
  | 'public-api-keys'
  | 'registration-deferred-fees'
  | 'registration-fee-enhancements'
  | 'regrade-preview'
  | 'revert-affiliation'
  | 'rollover-custom-fields'
  | 'search-filters-registration-date-kill'
  | 'select-fulfilment-provider'
  | 'shop'
  | 'snowflake-program-participant'
  | 'sponsor-advertising'
  | 'statistics-reports'
  | 'squad-list-statistics'
  | 'tpf-trial-products'
  | 'tpf-trial-opt-out-fake-door'
  | 'transaction-reports-customisation'
  | 'transaction-report-enhancement'
  | 'transaction-reports-filters'
  | 'transfer-preference-settings'
  | 'transfers-permits-list-sorting'
  | 'xero'

interface FeatureFlagDetails {
  squad: string
  ticketNo: string
  lastUpdatedDate: string
  longLived?: true
}

export const featureFlags: Record<FeatureFlag, FeatureFlagDetails> = {
  'additional-auto-approval-settings': {
    squad: 'anzacs',
    ticketNo: 'GO-21426',
    lastUpdatedDate: '16 Sep 2023',
  },
  'allocation-clash-dot': {
    squad: 'winners',
    ticketNo: 'GO-29016',
    lastUpdatedDate: '14 May 2024',
  },
  'all-play-grades': {
    squad: 'winners',
    ticketNo: 'GO-29196',
    lastUpdatedDate: '23 Jul 2024',
  },
  'batter-grace-period': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'best-players': {
    squad: 'sports',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'bonus-points': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'bulk-season-permits': {
    squad: 'winners',
    ticketNo: 'GO-28778',
    lastUpdatedDate: '27 Jun 2024',
  },
  'bulk-season-permits-season-restriction': {
    squad: 'winners',
    ticketNo: 'GO-29835',
    lastUpdatedDate: '13 Aug 2024',
  },
  'ca-admin-account-linking': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'career-club-statistics-report': {
    squad: 'sports',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'competition-teams-list-filters-sorting': {
    squad: 'anzacs',
    ticketNo: 'GO-28414',
    lastUpdatedDate: '05 Jun 2024',
  },
  'cricket-fielding-statistics': {
    squad: 'anzacs',
    ticketNo: 'GO-25993',
    lastUpdatedDate: '16 Aug 2023',
  },
  'environment-labels': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  fundraising: {
    squad: 'payments',
    ticketNo: 'GO-27655',
    lastUpdatedDate: '01 Mar 2023',
  },
  'fundraising-new-prompts': {
    squad: 'payments',
    ticketNo: 'GO-27655',
    lastUpdatedDate: '15 Mar 2023',
  },
  'financial-reports-enhancement': {
    squad: 'winners',
    ticketNo: 'GO-27813',
    lastUpdatedDate: '04 Mar 2024',
  },
  'fixture-from-round-number': {
    squad: 'winners',
    ticketNo: 'GO-27813',
    lastUpdatedDate: '24 May 2024',
  },
  'fixture-round-list-v2': {
    squad: 'winners',
    ticketNo: 'GO-29018',
    lastUpdatedDate: '15 May 2024',
  },
  'game-day-actions-menu': {
    squad: 'winners',
    ticketNo: 'GO-29785',
    lastUpdatedDate: '31 Jul 2024',
  },
  'game-payments': {
    squad: 'sports',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'game-state-indicators': {
    squad: 'anzacs',
    ticketNo: 'GO-26031',
    lastUpdatedDate: '1 Jan 2022',
  },
  'government-vouchers-report-enhancement': {
    squad: 'winners',
    ticketNo: 'GO-27813',
    lastUpdatedDate: '04 Mar 2024',
  },
  'gr4vy-embed-checkout': {
    squad: 'payments',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '15 Apr 2023',
  },
  'grades-sorting': {
    squad: 'winners',
    ticketNo: 'GO-28370',
    lastUpdatedDate: '09 Apr 2024',
  },
  'inbound-participant-permit-setting': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'in-game-positions': {
    squad: 'winners',
    ticketNo: 'GO-28059',
    lastUpdatedDate: '27 Mar 2024',
  },
  'kill-login': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'live-game-scorecard': {
    squad: 'anzacs',
    ticketNo: 'GO-25286',
    lastUpdatedDate: '1 Jan 2022',
  },
  'live-scored-lineup-locked': {
    squad: 'anzacs',
    ticketNo: 'GO-27141',
    lastUpdatedDate: '19 Dec 2023',
  },
  'live-scored-club-locked': {
    squad: 'anzacs',
    ticketNo: 'GO-27142',
    lastUpdatedDate: '19 Dec 2023',
  },
  'live-scoring': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'live-streaming-enabled': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  memberships: {
    squad: 'payments',
    ticketNo: 'GO-28119',
    lastUpdatedDate: '15 Mar 2023',
  },
  'memberships-new-prompts': {
    squad: 'payments',
    ticketNo: 'GO-28119',
    lastUpdatedDate: '30 Mar 2023',
  },
  'multi-factor-authentication-setup': {
    squad: 'baguettes',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'offence-list': {
    squad: 'winners',
    ticketNo: 'GO-28904',
    lastUpdatedDate: '20 Jun 2024',
  },
  'order-list-optimisation': {
    squad: 'baguettes',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'participant-reports-customisation': {
    squad: 'winners',
    ticketNo: 'GO-27891',
    lastUpdatedDate: '4 Jun 2024',
  },
  'participant-reports-filters': {
    squad: 'winners',
    ticketNo: 'GO-27891',
    lastUpdatedDate: '4 Jun 2024',
  },
  'participant-reports-season-multiselect': {
    squad: 'winners',
    ticketNo: 'GO-28410',
    lastUpdatedDate: '4 Jun 2024',
  },
  'participant-reports-templates': {
    squad: 'winners',
    ticketNo: 'GO-28418',
    lastUpdatedDate: '4 Jun 2024',
  },
  'participant-reports-unique': {
    squad: 'winners',
    ticketNo: 'GO-28396',
    lastUpdatedDate: '4 Jun 2024',
  },
  'participant-details-external-accounts': {
    squad: 'platform',
    ticketNo: 'GO-29284',
    lastUpdatedDate: '12 Jul 2024',
  },
  'permit-preference-settings': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  permits: {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'permits-commentary-box': {
    squad: 'anzacs',
    ticketNo: 'GO-29591',
    lastUpdatedDate: '18 Jul 2024',
  },
  'permits-list-to-from-dates': {
    squad: 'winners',
    ticketNo: 'GO-29499',
    lastUpdatedDate: '18 Jul 2024',
  },
  posts: {
    squad: 'nebula',
    ticketNo: 'GO-28674',
    lastUpdatedDate: '5 Jul 2024',
  },
  'product-payment-requests': {
    squad: 'payments',
    ticketNo: 'GO-28576',
    lastUpdatedDate: '7 Jun 2024',
  },
  'program-search-filters-sorting': {
    squad: 'winners',
    ticketNo: 'GO-28439',
    lastUpdatedDate: '05 Jun 2024',
  },
  'program-season-enhancements': {
    squad: 'sports',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'program-shop-link': {
    squad: 'anzacs',
    ticketNo: 'GO-25288',
    lastUpdatedDate: '1 Jan 2022',
  },
  'program-reports-customisation': {
    squad: 'winners',
    ticketNo: 'GO-28508',
    lastUpdatedDate: '06 May 2024',
  },
  'public-api-keys': {
    squad: 'baguettes',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'registration-deferred-fees': {
    squad: 'payments',
    ticketNo: 'GO-29553',
    lastUpdatedDate: '5 Jul 2024',
  },
  'registration-fee-enhancements': {
    squad: 'payments',
    ticketNo: 'GO-28821',
    lastUpdatedDate: '9 Jul 2024',
  },
  'regrade-preview': {
    squad: 'winners',
    ticketNo: 'GO-29136',
    lastUpdatedDate: '04 Jun 2024',
  },
  'revert-affiliation': {
    squad: 'anzacs',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'rollover-custom-fields': {
    squad: 'winners',
    ticketNo: 'GO-29567',
    lastUpdatedDate: '8 Jul 2024',
  },
  'search-filters-registration-date-kill': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
    longLived: true,
  },
  'select-fulfilment-provider': {
    squad: 'anzacs',
    ticketNo: 'GO-25170',
    lastUpdatedDate: '1 Jan 2022',
  },
  shop: {
    squad: 'payments',
    ticketNo: 'GO-28392',
    lastUpdatedDate: '1 May 2024',
  },
  'snowflake-program-participant': {
    squad: 'winners',
    ticketNo: 'GO-28504',
    lastUpdatedDate: '05 Jun 2024',
  },
  'sponsor-advertising': {
    squad: 'nebula',
    ticketNo: 'GO-27083',
    lastUpdatedDate: '15 Feb 2024',
  },
  'statistics-reports': {
    squad: 'sports',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  'squad-list-statistics': {
    squad: 'winners',
    ticketNo: 'GO-28906',
    lastUpdatedDate: '20 Jun 2024',
  },
  'tpf-trial-products': {
    squad: 'payments',
    ticketNo: 'GO-29106',
    lastUpdatedDate: '24 May 2024',
  },
  'tpf-trial-opt-out-fake-door': {
    squad: 'payments',
    ticketNo: 'GO-29861',
    lastUpdatedDate: '8 Aug 2024',
  },
  'transaction-reports-customisation': {
    squad: 'winners',
    ticketNo: 'GO-27891',
    lastUpdatedDate: '4 Jun 2024',
  },
  'transaction-report-enhancement': {
    squad: 'winners',
    ticketNo: 'GO-27707',
    lastUpdatedDate: '05 Feb 2024',
  },
  'transaction-reports-filters': {
    squad: 'winners',
    ticketNo: 'GO-28424',
    lastUpdatedDate: '4 Jun 2024',
  },
  'transfers-permits-list-sorting': {
    squad: 'winners',
    ticketNo: 'GO-29690',
    lastUpdatedDate: '31 Jul 2024',
  },
  'transfer-preference-settings': {
    squad: 'koalas',
    ticketNo: 'GO-28991',
    lastUpdatedDate: '1 Jan 2022',
  },
  xero: {
    squad: 'winners',
    ticketNo: 'GO-28215',
    lastUpdatedDate: '8 May 2024',
  },
}
