import { Location, WindowLocation } from '@reach/router'
import React from 'react'

export class UnauthorisedError extends Error {
  constructor(message?: string) {
    super(message)

    this.name = 'UnauthorisedError'
  }
}

const UnauthorisedBoundary: React.FC<{}> = ({ children }) => (
  <Location>
    {({ location }) => {
      if (
        (location as WindowLocation<{ unauthorised: boolean } | null>).state
          ?.unauthorised
      ) {
        throw new UnauthorisedError()
      }

      return children
    }}
  </Location>
)

export default UnauthorisedBoundary
