import Loader from '@gameonsports/components/lib/Loader'
import { WindowLocation } from '@reach/router'
import React from 'react'
import AccountContext from '../../context/AccountContext'
import OrganisationContext from '../../context/OrganisationContext'
import {
  useAppcuesOrgQuery,
  useCurrentAccountQuery,
  useOrganisationsTypesAndNamesQuery,
} from '../../generated/graphql'
import useOrg from '../../hooks/useOrg'
import ErrorPanel from '../ErrorPanel'

interface AppPreloaderProps {
  location: WindowLocation
  skipInitialiseQueries: boolean
}

const AppPreloader: React.FC<AppPreloaderProps> = React.memo(
  ({ skipInitialiseQueries, children }) => {
    // Setup org from URL or from local storage
    const org = useOrg(skipInitialiseQueries)
    const { data, loading } = useCurrentAccountQuery({
      skip: skipInitialiseQueries,
    })

    const { data: orgData, loading: orgLoading } =
      useOrganisationsTypesAndNamesQuery({
        skip: skipInitialiseQueries,
        fetchPolicy: 'cache-first',
      })

    useAppcuesOrgQuery({
      skip: !(org && data),
      variables: {
        orgId: org?.id ?? '',
      },
      // Set organisation id in header since it might not be available
      // from local storage on first load
      context: {
        headers: {
          'X-Organisation-Id': org?.id,
        },
      },
      onCompleted: orgData => {
        if (data?.account?.profile?.id && window.Appcues) {
          window.Appcues.identify(data.account.profile?.id, {
            firstName: data.account.profile?.firstName,
            lastName: data.account.profile?.lastName,
            email: data.account.profile?.email,
            state: data.account.profile?.address?.state,
            currentOrgID: orgData.organisation?.id,
            currentOrgType: orgData.organisation?.type,
            currentOrgName: orgData.organisation?.name,
          })

          window.Appcues.group(orgData.organisation?.id, {
            orgID: orgData.organisation?.id,
            orgName: orgData.organisation?.name,
            orgType: orgData.organisation?.type,
            orgState: orgData.organisation?.address?.state,
            parentOrgID: orgData.organisation?.parent?.id,
            parentOrgName: orgData.organisation?.parent?.name,
            parentOrgType: orgData.organisation?.parent?.type,
            parentOrgState: orgData.organisation?.parent?.address?.state,
            isStripePaymentSetup:
              orgData.organisation?.stripeConnectAccountStatus?.value ?? null,
          })
        }
      },
    })

    if (loading || orgLoading) {
      return <Loader />
    }

    const account = data?.account
    const organisations = orgData?.organisations

    if (
      !skipInitialiseQueries &&
      account &&
      organisations &&
      organisations.length === 0
    ) {
      return (
        <ErrorPanel
          title="401"
          description="You don’t have access to view this."
          data-testid="unauthorised-error"
        />
      )
    }

    return (
      <AccountContext.Provider value={{ user: account }}>
        <OrganisationContext.Provider value={{ organisationId: org?.id ?? '' }}>
          {children}
        </OrganisationContext.Provider>
      </AccountContext.Provider>
    )
  },
  (prevProps, nextProps) =>
    prevProps.skipInitialiseQueries === nextProps.skipInitialiseQueries &&
    prevProps.location.pathname === nextProps.location.pathname,
)

export default AppPreloader
