import React from 'react'

interface LogoProps {
  inverted?: boolean
  className?: string
}

const Logo: React.FC<LogoProps> = ({ inverted, className, ...props }) => {
  const fill = inverted ? '#fff' : '#150578'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 83 37.069"
      className={className}
      {...props}
    >
      <title>PlayHQ</title>
      <g transform="translate(-1591.35 -1598.533)">
        <path
          d="M1681.671,1606.059l-.147,1.286a38.343,38.343,0,0,0-35.277-1.26v-.052a38.22,38.22,0,0,1,16.128-6.933c.5-.085.984-.138,1.476-.207a38.229,38.229,0,0,1,17.966,1.813A39.393,39.393,0,0,1,1681.671,1606.059Z"
          transform="translate(-7.501)"
          fill="#009ffd"
        />
        <path
          d="M1646.219,1607.225c-.052.039-.1.08-.154.119.052-.025.106-.046.159-.071Z"
          transform="translate(-7.476 -1.188)"
          fill="#be33ff"
        />
        <path
          d="M1663.822,1598.9c-.492.069-.976.122-1.476.207a38.22,38.22,0,0,0-16.128,6.933,38.537,38.537,0,0,1-.147-5.292A38.15,38.15,0,0,1,1663.822,1598.9Z"
          transform="translate(-7.473 -0.005)"
          fill="#be33ff"
        />
        <path
          d="M1603.775,1610.651a4.334,4.334,0,0,1,1.277,3.293q0,.24-.047.811a5.984,5.984,0,0,1-1.027,2.745,5.563,5.563,0,0,1-2.159,1.837,6.7,6.7,0,0,1-2.971.644h-2.816q-.094,0-.142.119l-.692,5.752a.389.389,0,0,1-.107.2.273.273,0,0,1-.2.084h-3.293a.234.234,0,0,1-.191-.084.225.225,0,0,1-.047-.2l1.98-16.131a.385.385,0,0,1,.108-.2.277.277,0,0,1,.2-.083h6.681A4.792,4.792,0,0,1,1603.775,1610.651Zm-3.257,5.536a2.22,2.22,0,0,0,.668-1.671,1.577,1.577,0,0,0-.514-1.229,1.961,1.961,0,0,0-1.372-.464h-2.385c-.065,0-.112.04-.144.118l-.453,3.77c-.032.08,0,.119.1.119h2.386A2.375,2.375,0,0,0,1600.519,1616.187Z"
          transform="translate(0 -1.49)"
          fill={fill}
        />
        <path
          d="M1607.084,1626.054a.226.226,0,0,1-.048-.2l1.981-16.131a.383.383,0,0,1,.107-.2.277.277,0,0,1,.2-.083h3.293a.243.243,0,0,1,.192.083.229.229,0,0,1,.047.2l-1.981,16.131a.388.388,0,0,1-.107.2.271.271,0,0,1-.2.084h-3.293A.236.236,0,0,1,1607.084,1626.054Z"
          transform="translate(-2.143 -1.49)"
          fill={fill}
        />
        <path
          d="M1622.392,1614.546a.276.276,0,0,1,.2-.084h3.294a.241.241,0,0,1,.191.084.23.23,0,0,1,.048.2l-1.456,11.764a.394.394,0,0,1-.107.2.273.273,0,0,1-.2.084h-3.293a.233.233,0,0,1-.191-.084.223.223,0,0,1-.048-.2l.12-.907a.108.108,0,0,0-.036-.095c-.024-.016-.06,0-.108.048a3.552,3.552,0,0,1-2.934,1.433,3.775,3.775,0,0,1-2.637-.99,4.528,4.528,0,0,1-1.372-2.686,6.133,6.133,0,0,1-.1-1.146,12.344,12.344,0,0,1,.119-1.575,8.949,8.949,0,0,1,.836-3.054,5.907,5.907,0,0,1,1.957-2.351,4.889,4.889,0,0,1,2.911-.918,2.584,2.584,0,0,1,2.458,1.217c.032.047.063.064.095.047a.1.1,0,0,0,.048-.095l.095-.692A.387.387,0,0,1,1622.392,1614.546Zm-.848,6.1a8.128,8.128,0,0,0,.073-.977,3.079,3.079,0,0,0-.144-.979,1.465,1.465,0,0,0-.489-.751,1.358,1.358,0,0,0-.87-.275,1.732,1.732,0,0,0-.979.287,2.1,2.1,0,0,0-.692.787,5.178,5.178,0,0,0-.548,1.909,6.726,6.726,0,0,0-.047.906,3.2,3.2,0,0,0,.119.979,1.534,1.534,0,0,0,.513.776,1.415,1.415,0,0,0,.919.3,1.773,1.773,0,0,0,1.622-1.1A4.456,4.456,0,0,0,1621.544,1620.642Z"
          transform="translate(-3.063 -2.151)"
          fill={fill}
        />
        <path
          d="M1627.916,1631.287l.334-2.674a.394.394,0,0,1,.106-.2.259.259,0,0,1,.18-.084,6.241,6.241,0,0,0,2.16-.274,1.659,1.659,0,0,0,.943-1.158.338.338,0,0,0,.023-.143l-2.267-11.954v-.072a.261.261,0,0,1,.072-.192.258.258,0,0,1,.191-.071h3.507a.237.237,0,0,1,.263.238l.74,7.063c0,.064.02.095.06.095s.067-.031.083-.095l2.458-7.063a.338.338,0,0,1,.334-.238h3.413a.232.232,0,0,1,.2.084.257.257,0,0,1-.013.25l-5.416,12.265a13.019,13.019,0,0,1-1.552,2.707,4.32,4.32,0,0,1-2,1.362,11.1,11.1,0,0,1-3.508.44h-.167Q1627.916,1631.573,1627.916,1631.287Z"
          transform="translate(-4.996 -2.177)"
          fill={fill}
        />
        <g transform="translate(1636.302 1602.39)">
          <path
            d="M1662.273,1603a38.149,38.149,0,0,0-16.412,3.7c-.052.027-.1.044-.155.069-.777.38-1.545.768-2.288,1.192a38.129,38.129,0,0,0,5.057,14.632l1.688-13.746a.262.262,0,0,1,.095-.2.319.319,0,0,1,.216-.083h3.268c.175,0,.263.095.263.286l-.764,6.156c0,.08.032.12.1.12h4.343a.116.116,0,0,0,.084-.036.113.113,0,0,0,.035-.084l.764-6.156a.389.389,0,0,1,.107-.2.278.278,0,0,1,.2-.083h3.294a.238.238,0,0,1,.19.083.227.227,0,0,1,.048.2l-1.981,16.131a.388.388,0,0,1-.108.2.269.269,0,0,1-.2.084h-3.292a.238.238,0,0,1-.192-.084.228.228,0,0,1-.047-.2l.763-6.347c.032-.079,0-.119-.095-.119h-4.318c-.064,0-.111.04-.144.119l-1.04,8.61a38.494,38.494,0,0,0,10.526,8.978,37.946,37.946,0,0,0,11.323-10l-1.692-1.7c-.12.07-.217.167-.343.23a7.431,7.431,0,0,1-3.413.775,5.676,5.676,0,0,1-4.079-1.431,5.086,5.086,0,0,1-1.5-3.866,6.918,6.918,0,0,1,.048-.93l.6-4.773a7,7,0,0,1,1.182-3.21,6.665,6.665,0,0,1,2.48-2.17,7.3,7.3,0,0,1,3.377-.776,6.484,6.484,0,0,1,2.982.645,4.593,4.593,0,0,1,1.945,1.825,5.425,5.425,0,0,1,.679,2.756,6.627,6.627,0,0,1-.047.931l-.573,4.773a7.684,7.684,0,0,1-.421,1.786l1.2,1.2c.023.023.079.074.145.134a37.843,37.843,0,0,0,4.982-14.454A38.206,38.206,0,0,0,1662.273,1603Z"
            transform="translate(-1643.417 -1603)"
            fill={fill}
          />
          <path
            d="M1671.611,1613.889a3.161,3.161,0,0,0-.919,1.969l-.6,4.939a3.73,3.73,0,0,0-.023.477,2.273,2.273,0,0,0,.572,1.646,2.046,2.046,0,0,0,1.551.6,2.628,2.628,0,0,0,1.9-.752,3.166,3.166,0,0,0,.919-1.969l.62-4.939a2.875,2.875,0,0,0,.024-.43,2.334,2.334,0,0,0-.585-1.67,2.075,2.075,0,0,0-1.587-.621A2.593,2.593,0,0,0,1671.611,1613.889Z"
            transform="translate(-1647.059 -1604.385)"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
