import React from 'react'
import { GlossaryQuery, useGlossaryQuery } from '../../generated/graphql'

export type Glossary = GlossaryQuery['tenantConfiguration']['glossary']

export const GlossaryContext = React.createContext<Glossary | undefined>(
  undefined,
)

interface GlossaryProviderProps {
  children: React.ReactNode
  skipInitialiseQueries?: boolean
}

export const GlossaryProvider = ({
  children,
  skipInitialiseQueries,
}: GlossaryProviderProps) => {
  const { data } = useGlossaryQuery({ skip: skipInitialiseQueries })

  return (
    <GlossaryContext.Provider value={data?.tenantConfiguration.glossary}>
      {children}
    </GlossaryContext.Provider>
  )
}
