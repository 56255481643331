import { useEffect, useState } from 'react'
import localStorageKeys from '../constants/localStorageKeys'
import { FeatureFlag } from '../flags'
import useFeatureFlagOn from './useFeatureFlagOn'

export enum Feature {
  clubMembershipsNewDot,
  fundraisingNewDot,
  productsNewPill,
  reportsNewPill,
  competitionsNewDot,
  compReportCustomisationNewPill,
  financialReportsNewDot,
  transactionCustomisationNewPill,
  transactionDateFilterNewPill,
  programReportsNewDot,
  programReportsCustomisationNewPill,
  postsNewPill,
}

const featureFlagMap: Record<Feature, FeatureFlag> = {
  [Feature.clubMembershipsNewDot]: 'memberships-new-prompts',
  [Feature.fundraisingNewDot]: 'fundraising-new-prompts',
  [Feature.productsNewPill]: 'fundraising-new-prompts',
  [Feature.reportsNewPill]: 'participant-reports-customisation',
  [Feature.competitionsNewDot]: 'participant-reports-customisation',
  [Feature.compReportCustomisationNewPill]: 'participant-reports-customisation',
  [Feature.financialReportsNewDot]: 'transaction-reports-customisation',
  [Feature.transactionCustomisationNewPill]:
    'transaction-reports-customisation',
  [Feature.transactionDateFilterNewPill]: 'transaction-reports-customisation',
  [Feature.programReportsNewDot]: 'program-reports-customisation',
  [Feature.programReportsCustomisationNewPill]: 'program-reports-customisation',
  [Feature.postsNewPill]: 'posts',
}

const localStorageMap: Record<Feature, keyof typeof localStorageKeys> = {
  [Feature.clubMembershipsNewDot]: 'hideClubMembershipsNewDot',
  [Feature.fundraisingNewDot]: 'hideFundraisingNewDot',
  [Feature.productsNewPill]: 'hideProductsNewPill',
  [Feature.reportsNewPill]: 'hideReportsNewPill',
  [Feature.competitionsNewDot]: 'hideCompetitionsNewDot',
  [Feature.compReportCustomisationNewPill]:
    'hideCompReportCustomisationNewPill',
  [Feature.financialReportsNewDot]: 'hideFinancialReportsNewDot',
  [Feature.transactionCustomisationNewPill]:
    'hideTransactionCustomisationNewPill',
  [Feature.transactionDateFilterNewPill]: 'hideTransactionDateFilterNewPill',
  [Feature.programReportsNewDot]: 'hideProgramReportsNewDot',
  [Feature.programReportsCustomisationNewPill]:
    'hideProgramReportsCustomisationNewPill',
  [Feature.postsNewPill]: 'hidePostsNewPill',
}

interface ScheduleNewPillHideOptions {
  isImmediate?: boolean
}

/**
 * returns tuple [a, b] where
 * a - boolean flag which indicates when to highlight new functionality
 * b - callback which handles hiding new functionality indicator,
 * with 1-second delay after user interaction
 */
const useNewFeatureIndicator = (
  feature: Feature,
): [boolean, (options?: ScheduleNewPillHideOptions) => void] => {
  const featureFlagName = featureFlagMap[feature]
  const isFFOn = useFeatureFlagOn(featureFlagName)
  const localStorageKey = localStorageKeys[localStorageMap[feature]]
  const localStorageValue =
    localStorageKey && localStorage.getItem(localStorageKey)
  // Want to show the new pill if FF is on and the user hasn't hidden it
  const showPill = isFFOn && !localStorageValue

  const [showNewPillTimeout, setNewPillTimeout] =
    useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (showNewPillTimeout) {
        clearTimeout(showNewPillTimeout)
      }
    }
  }, [showNewPillTimeout])

  const scheduleNewPillHide = (
    options: ScheduleNewPillHideOptions | undefined,
  ) => {
    if (!isFFOn || showNewPillTimeout || !showPill) {
      return
    }

    if (options?.isImmediate && localStorageKey) {
      return localStorage.setItem(localStorageKey, 'true')
    }

    setNewPillTimeout(
      // Hide the new pill after 1 second
      setTimeout(() => {
        if (localStorageKey) localStorage.setItem(localStorageKey, 'true')
        showNewPillTimeout && clearTimeout(showNewPillTimeout)
        setNewPillTimeout(null)
      }, 1000),
    )
  }

  return [showPill, scheduleNewPillHide]
}

export default useNewFeatureIndicator
