import _slugify from 'slugify'

/**
 * Capitalize the first letter of a given string. Example `"hello" -> "Hello"`.
 */
export const toTitleCase = (str: string) =>
  str[0]?.toUpperCase() + str.substring(1)?.toLowerCase()

/**
 * Capitalize the first letter of the first word, and every word following a
 * space of a given string. Example `"hello there" -> "Hello There"`.
 */
export const toTitleCaseRecursive = (str: string) =>
  str
    .split(/ /g)
    .map(s => toTitleCase(s))
    .join(' ')

export const removeSpaces = (str: string) => String(str).replace(/ /g, '')

export const kebabCase = (str: string) =>
  str.replace(/[\s_]+/g, '-').toLowerCase()

export const slugify = (str: string) =>
  _slugify(str, { lower: true, remove: /[^\w- ]/g })

const splitOn = (x: string) => (str: string) => str.split(x)

export const splitOnColon = splitOn(':')
