import { useContext } from 'react'
import AccountContext from '../context/AccountContext'
import OrganisationContext from '../context/OrganisationContext'

type Role =
  | 'CLUB_ADMIN'
  | 'FULL_ACCESS'
  | 'SUPER_ADMIN'
  | 'ADMIN_BODY'
  | 'AFFILIATE_ADMIN'
  | 'GLOBAL_SUPER_ADMIN'

const useRole = (role: Role) => {
  // TODO: Update this to use `useParams()` to get the org id out of the url params
  const { organisationId } = useContext(OrganisationContext)
  const { user } = useContext(AccountContext)

  if (!user) {
    return {
      isRole: false,
    }
  }

  // BUG: A super admin should have access to the *entire* tenant, not just the
  // organisation that contains the super admin permission (which is usually the
  // top level organisation in the tenant, such as BV). To fix GO-25736 this
  // introduces a new 'GLOBAL_SUPER_ADMIN' role, which ignores the orgId entirely.
  // GO-25758 has been raised to investigate the issue further and clean up the
  // expected functionality.
  if (
    role === 'GLOBAL_SUPER_ADMIN' &&
    user.permissions.some(permission => permission.role.value === 'SUPER_ADMIN')
  ) {
    return { isRole: true }
  }

  return {
    isRole: user.permissions
      .filter(p => p.organisation.id === organisationId)
      .some(permission => permission.role.value === role),
  }
}

export default useRole
