import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import Button from '@gameonsports/components/lib/Button'
import { Stack } from '@gameonsports/components/lib/StackV2'
import { Text } from '@gameonsports/components/lib/TextV3'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { APP_VERSION } from '../../appVersion'
import NavigationContext from '../../context/NavigationContext'
import { OrganisationType } from '../../generated/graphql'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import useNewFeatureIndicator, {
  Feature,
} from '../../hooks/useNewFeatureIndicator'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import useOrgType from '../../hooks/useOrgType'
import useTransfersDisabled from '../../hooks/useTransfersDisabled'
import {
  getEnvironmentLabel,
  getIsProduction,
} from '../../utils/environment-label'
import { media } from '../../utils/styled-components-utils'
import FeatureFlag from '../FeatureFlag/FeatureFlag'
import Logo from '../Logo'
import NavigationFooter from '../NavigationFooter'
import NavItem, { SubNavItem } from '../NavItem'
import NoMarginText from '../NoMarginText'
import Notification from '../Notification/Notification'
import { OrganisationSelect } from '../OrganisationSelect'

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
`

const NavHeader = styled.header`
  min-width: 0;
  position: relative;
  padding: 1.5rem 1rem;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.black400};
  color: ${props => props.theme.white400};

  ${media.desktop`
    text-align: left;
  `}
`

const DesktopLogo = styled(Logo)`
  display: none;
  width: 9rem;
  margin-left: 2.5rem;

  ${media.desktop`
    display: block;
  `}
`

const MobileHeading = styled(NoMarginText)`
  display: block;

  ${media.desktop`
    display: none;
  `}
`

const MobileExpandButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: -0.75rem;
  transform: translateY(-50%);
  border: 1px solid ${props => props.theme.white400};

  svg {
    height: 0.5rem;
    width: 0.5rem;
  }

  ${media.desktop`
    display: none;
  `}
`

const MobileExpandContent = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px; /* stylelint-disable-line */
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
`

const Nav = styled.nav<{ isExpanded: boolean }>`
  position: relative;
  display: grid;
  grid-template-rows: auto auto 1fr;
  background-color: ${props => props.theme.secondary};
  z-index: 999; /* Set to 999 to ensure wipes across content, but leaves plenty of room for a modal onion-skin */
  width: 100%;
  transition: width 150ms ease;
  max-height: 100vh;

  ${props =>
    !props.isExpanded &&
    `
    ${NavList} {
      li {
        button {
          z-index: -1;
        }
      }
    }
  `}

  ${props =>
    props.isExpanded &&
    `
    width: 75vw;

    ${NavHeader} {
      text-align: left;
    }

    ${MobileHeading} {
      display: none;
    }

    ${DesktopLogo} {
      display: block;
    }
  `}

  ${media.desktop`
    width: 100%;

    &::after {
      content: none;
    }

    ${NavList} {
      li {
        button {
          z-index: 0;
        }
      }
    }
  `}

  @media print {
    display: none;
  }
`

const StyledNotification = styled(Notification)<{ $navExpanded: boolean }>`
  ${mq.down('desktop')} {
    ${props =>
      !props.$navExpanded &&
      css`
        display: none;
      `}
  }

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  justify-content: center;
  font-weight: 600;

  & > div:first-child {
    height: 1rem;
    width: 1rem;
    background-color: ${props => props.theme.black400};
  }
`

const BackgroundOverlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.secondary};
  opacity: 0.4;
  z-index: 1;
`

const TextWrap = styled(Text)`
  overflow-wrap: anywhere;
`

const Navigation: React.FC<{ organisationId: string }> = ({
  organisationId,
}) => {
  const isFundraisingOn = useFeatureFlagOn('fundraising')
  const isPermitsOn = useFeatureFlagOn('permits')
  const isEnvironmentLabelsOn = useFeatureFlagOn('environment-labels')
  const [showProductsNewPill, hideProductsNewPill] = useNewFeatureIndicator(
    Feature.productsNewPill,
  )
  const [showReportsNewPill, hideReportsNewPill] = useNewFeatureIndicator(
    Feature.reportsNewPill,
  )
  const [showPostsNewPill, hidePostsNewPill] = useNewFeatureIndicator(
    Feature.postsNewPill,
  )
  const [navExpanded, setNavExpanded] = useState(false)
  const isClub = useOrgType(OrganisationType.Club)
  const transfersDisabled = useTransfersDisabled()

  const navRef = React.createRef<HTMLDivElement>()

  const closeNavOnClickOutside = () => {
    if (navExpanded) {
      setNavExpanded(false)
    }
  }

  useOnOutsideClick(navRef, closeNavOnClickOutside)

  const transfersAndPermitsLabel = [
    !transfersDisabled && 'Transfers',
    isPermitsOn && 'Permits',
  ]
    .filter(Boolean)
    .join(' & ')

  return (
    <NavigationContext.Provider value={{ navExpanded }}>
      {navExpanded && <BackgroundOverlay />}
      <Nav isExpanded={navExpanded} ref={navRef}>
        <NavHeader>
          <Stack gap="xl">
            {isEnvironmentLabelsOn && !getIsProduction() && (
              <StyledNotification
                variant="info"
                $navExpanded={navExpanded}
                data-testid="environment-label"
              >
                <Stack gap="xs">
                  <Text as="div">{getEnvironmentLabel()}</Text>
                  <TextWrap as="div" size="14" color="darkGrey400">
                    {APP_VERSION}
                  </TextWrap>
                </Stack>
              </StyledNotification>
            )}
            <DesktopLogo data-testid="logo" inverted />
          </Stack>
          <MobileHeading size="28" weight="700" as="h1">
            P
          </MobileHeading>
          <MobileExpandButton
            icon={navExpanded ? 'left-arrow' : 'right-arrow'}
            variant="secondary"
            size="small"
            onClick={() => setNavExpanded(!navExpanded)}
          >
            <MobileExpandContent>Open navigation</MobileExpandContent>
          </MobileExpandButton>
        </NavHeader>
        <OrganisationSelect
          organisationId={organisationId}
          isExpanded={navExpanded}
        />
        <NavList>
          <NavItem
            label={isClub ? 'Competitions' : 'Competition Management'}
            to={`/org/${organisationId}/competitions`}
            icon="trophy"
            getPropsOverride={() => ({
              'aria-current':
                window.location.pathname.startsWith(
                  `/org/${organisationId}/competitions`,
                ) ||
                window.location.pathname.startsWith(
                  `/org/${organisationId}/seasons`,
                )
                  ? 'page'
                  : undefined,
            })}
          />
          <NavItem
            label="Game Day"
            to={`/org/${organisationId}/games`}
            icon="fullscoreboard"
          />
          <NavItem
            label="Programs"
            to={`/org/${organisationId}/programs`}
            getPropsOverride={() => ({
              'aria-current':
                window.location.pathname.startsWith(
                  `/org/${organisationId}/programs`,
                ) ||
                window.location.pathname.startsWith(
                  `/org/${organisationId}/program-seasons`,
                )
                  ? 'page'
                  : undefined,
            })}
            icon="programs"
          />
          <NavItem
            label="Participants"
            to={`/org/${organisationId}/participants`}
            icon="participants"
          />
          {!isClub && (
            <NavItem
              label="Incidents"
              to={`/org/${organisationId}/incidents/game`}
              getPropsOverride={() => ({
                'aria-current': window.location.pathname.startsWith(
                  `/org/${organisationId}/incidents`,
                )
                  ? 'page'
                  : undefined,
              })}
              icon="warning-outline"
            />
          )}
          {transfersAndPermitsLabel && (
            <NavItem
              label={transfersAndPermitsLabel}
              to={`/org/${organisationId}/transfers-and-permits`}
              icon="transfer"
            />
          )}
          {isFundraisingOn ? (
            <NavItem
              label="Products"
              to={`/org/${organisationId}/products/merchandise`}
              getPropsOverride={() => ({
                'aria-current': window.location.pathname.startsWith(
                  `/org/${organisationId}/products`,
                )
                  ? 'page'
                  : undefined,
              })}
              icon="merchandise"
              showNewPill={showProductsNewPill}
              onClick={hideProductsNewPill}
            />
          ) : (
            <NavItem
              label="Merchandise"
              to={`/org/${organisationId}/merchandise`}
              icon="merchandise"
            >
              <SubNavItem
                label="Products"
                to={`/org/${organisationId}/products`}
                icon="jersey"
              />
              <SubNavItem
                label="Orders"
                to={`/org/${organisationId}/product-orders`}
                icon="list"
              />
            </NavItem>
          )}
          <NavItem
            label="Venues"
            to={`/org/${organisationId}/venues`}
            icon="stadium"
          />
          <NavItem
            label="Reports"
            to={`/org/${organisationId}/reports`}
            icon="graph"
            showNewPill={showReportsNewPill}
            onClick={hideReportsNewPill}
          />
          <FeatureFlag name="posts">
            <NavItem
              label="Posts"
              to={`/org/${organisationId}/posts`}
              icon="posts"
              showNewPill={showPostsNewPill}
              onClick={hidePostsNewPill}
            />
          </FeatureFlag>
          <NavItem
            label="My Organisation"
            to={`/org/${organisationId}/my-organisation`}
            icon="globe"
          >
            <SubNavItem
              label="Overview"
              to={`/org/${organisationId}/my-organisation/overview`}
              icon="location"
            />
            <SubNavItem
              label="Admins"
              to={`/org/${organisationId}/my-organisation/admins`}
              icon="adminuser"
            />
          </NavItem>
          <NavItem
            label="Settings"
            to={`/org/${organisationId}/settings`}
            icon="settings"
          >
            <SubNavItem
              label="General"
              to={`/org/${organisationId}/settings/general`}
              icon="settings-fill"
            />
            <SubNavItem
              label="Organisations"
              to={`/org/${organisationId}/settings/organisations`}
              icon="globe"
            />
          </NavItem>
        </NavList>
        <NavigationFooter
          onClickAccount={() => !navExpanded && setNavExpanded(!navExpanded)}
          isExpanded={navExpanded}
        />
      </Nav>
    </NavigationContext.Provider>
  )
}

export default Navigation
