import { PushLogOptions, faro } from '@grafana/faro-web-sdk'

export const pushLog = (args: unknown[], options?: PushLogOptions): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.pushLog(args, options)
}

export { LogLevel } from '@grafana/faro-web-sdk'
