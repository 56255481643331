import isEmpty from 'lodash/isEmpty'
import { LOCAL_STORAGE_KEY } from '../constants/orgKey'
import { useOrganisationsTypesAndNamesQuery } from '../generated/graphql'
import useMatch from './useMatch'

const useOrg = (skip?: boolean) => {
  const match = useMatch<{ organisationId: string }>('/org/:organisationId/*')
  const { data } = useOrganisationsTypesAndNamesQuery({
    fetchPolicy: 'cache-first',
    skip,
  })

  if (!data || !data.organisations) {
    return
  }

  const orgId = match
    ? match.organisationId
    : localStorage.getItem(LOCAL_STORAGE_KEY) ||
      (data.organisations[0] && data.organisations[0].id)

  const org = !isEmpty(orgId)
    ? data.organisations.find(o => o.id === orgId) || data.organisations[0]
    : undefined

  if (!org) {
    return
  }

  localStorage.setItem(LOCAL_STORAGE_KEY, org.id)
  return org
}

export default useOrg
