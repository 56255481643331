import React from 'react'
import { CurrentAccountAccount } from '../../generated/graphql'

export interface AccountContextValues {
  user?: CurrentAccountAccount | null
}

const AccountContext = React.createContext<AccountContextValues>({
  user: null,
})

export default AccountContext
