import React from 'react'

interface OrganisationContextValues {
  organisationId: string
}

const OrganisationContext = React.createContext<OrganisationContextValues>({
  organisationId: '',
})

export default OrganisationContext
