import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import { Box } from '@gameonsports/components/lib/Box'
import Button from '@gameonsports/components/lib/Button'
import Icon from '@gameonsports/components/lib/Icon'
import Input from '@gameonsports/components/lib/Input'
import Loader from '@gameonsports/components/lib/Loader'
import { Stack } from '@gameonsports/components/lib/Stack'
import { Text } from '@gameonsports/components/lib/Text'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  useGetOrganisationNameQuery,
  useGetOrganisationsLazyQuery,
  useOrganisationNamesPaginatedQuery,
} from '../../generated/graphql'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import useToggle from '../../hooks/useToggle'

const OrganisationSelectContainer = styled(Stack)<{ $isExpanded: boolean }>`
  ${props =>
    !props.$isExpanded &&
    css`
      display: none;
    `}
  ${mq.up('desktop')} {
    display: flex;
  }
`

const StyledFormContainer = styled.div`
  padding: 0.75rem;
`

const StyledInput = styled(Input)`
  input {
    padding-left: 2.2rem;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
`

const StyledButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0.25rem;
`

const EmptyContainer = styled.div`
  padding: 0.75rem 0 0.25rem 1.75rem;
`

const SelectButtonContainer = styled.button<{ $highlight: boolean }>`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.black400};
  }

  ${props =>
    props.$highlight &&
    css`
      background-color: ${props => props.theme.black400};
    `}
`

const Container = styled.div`
  position: absolute;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 20rem;
  top: 12rem;
  left: 0.5rem;
  max-height: calc(100% - 20rem);
  background-color: ${props => props.theme.white400};
  z-index: 2;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);

  ${mq.up('largeDesktop')} {
    top: 11.5rem;
  }
`

const MenuItem = styled.li<{ $isActive: boolean }>`
  padding: 0.75rem 1.75rem;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
  z-index: 2;
  font-size: 0.875rem;
  color: ${props => props.theme.black400};

  ${props =>
    props.$isActive &&
    css`
      background-color: ${props.theme.grey400};
    `}

  &:hover {
    background-color: ${props => props.theme.grey400};
  }
`

const DEBOUNCE_MS = 500
const MIN_QUERY_LENGTH = 3

type OrganisationName = {
  value: string
  name: string
}

interface OrganisationSelectProps {
  organisationId: string
  isExpanded?: boolean
}

export const OrganisationSelect = ({
  organisationId,
  isExpanded = true,
}: OrganisationSelectProps) => {
  const [isOpen, toggleMenu] = useToggle()
  const [organisations, setOrganisations] = useState<OrganisationName[]>([])
  const [selectedOrgName, setSelectedOrgName] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [activeItemIndex, setActiveItemIndex] = useState<number>(-1)
  const [searchInProgress, setSearchInProgress] = useState(false)

  // Use ref to close the org selector when clicking outside, using the same
  // pattern as Modal.tsx
  const orgSelectRef = React.createRef<HTMLDivElement>()
  const toggleSelectRef = React.createRef<HTMLButtonElement>()

  // Close the select container when clicking outside
  const closeSelectOnClickOutside = () => {
    toggleMenu()
  }

  // When clicking outside of the dropdown or the button, run the handler
  useOnOutsideClick([orgSelectRef, toggleSelectRef], closeSelectOnClickOutside)

  // Get the name for the currently selected org
  useGetOrganisationNameQuery({
    variables: {
      id: organisationId,
    },
    onCompleted: data => {
      setSelectedOrgName(data.organisation?.name ?? '')
    },
    fetchPolicy: 'network-only',
    skip: !organisationId,
  })

  // Load the first 10 organisations the admin has access to
  const { data } = useOrganisationNamesPaginatedQuery({
    variables: {
      filter: {
        page: 1,
        limit: 10,
      },
    },
  })

  // Replace the org list with the filtered query
  const [
    getOrganisations,
    { data: searchData, loading: searchLoading, called: searchCalled },
  ] = useGetOrganisationsLazyQuery()

  // When the menu is closed clear the search box value, so it shows the default results when opened next
  useEffect(() => {
    if (!isOpen) {
      setInputValue('')
    }
  }, [isOpen])

  // When the menu is closed or request loading is complete, update our searchInProgress state and reset the keyboard highlighted index
  useEffect(() => {
    if (!isOpen || !searchLoading) {
      setSearchInProgress(false)
      setActiveItemIndex(-1)
    }
  }, [isOpen, searchLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetOrganisations = useCallback(
    debounce((value: string) => {
      if (value.length >= MIN_QUERY_LENGTH) {
        getOrganisations({
          variables: {
            filter: {
              name: value,
            },
          },
        })
      }
    }, DEBOUNCE_MS),
    [getOrganisations],
  )

  const onChange = (value: string) => {
    // When the org is changed, navigate to URL '/org/orgId'
    if (value !== organisationId) {
      window.location.assign(`/org/${value}`)
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  useEffect(() => {
    activeItemIndex >= 0 &&
      orgSelectRef?.current
        ?.getElementsByTagName('li')
        [activeItemIndex]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
  }, [orgSelectRef, activeItemIndex])

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case 'ArrowUp': {
        activeItemIndex > -1 && setActiveItemIndex(activeItemIndex - 1)
        e.preventDefault() // prevent ArrowUp from moving the cursor in the input box to the start
        break
      }
      case 'ArrowDown': {
        activeItemIndex < organisations.length - 1 &&
          setActiveItemIndex(activeItemIndex + 1)
        e.preventDefault() // prevent ArrowDown from moving the cursor in the input box to the end
        break
      }
      case 'NumpadEnter': // Fallthrough into Enter case
      case 'Enter': {
        if (organisations.length && activeItemIndex > -1) {
          onChange(organisations[activeItemIndex].value)
          toggleMenu()
        }
        break
      }
      case 'Escape': {
        toggleMenu()
        break
      }
    }
  }

  useEffect(() => {
    if (!searchInProgress) {
      searchCalled && inputValue.length >= MIN_QUERY_LENGTH
        ? setOrganisations(
            searchData?.organisations.map(o => ({
              value: o.id,
              name: o.name,
            })) ?? [],
          )
        : setOrganisations(
            data?.organisationsPaginated.results.map(o => ({
              value: o.id,
              name: o.name,
            })) ?? [],
          )
    }
  }, [
    setOrganisations,
    inputValue,
    data,
    searchData,
    searchCalled,
    searchInProgress,
  ])

  return (
    <OrganisationSelectContainer $isExpanded={isExpanded}>
      <SelectButtonContainer
        $highlight={isOpen}
        onClick={() => {
          toggleMenu()
        }}
        aria-label="Organisation Selector"
        ref={toggleSelectRef}
      >
        <Stack
          paddingX="m"
          paddingTop="s"
          paddingBottom="l"
          borderWidth="1px"
          borderColor="black400"
          borderBottomStyle="solid"
          gap="s"
        >
          <Text size="12" color="grey400" textAlign="start">
            Using as
          </Text>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              textAlign="left"
              color="white400"
              weight="600"
              data-testid="organisation-name"
            >
              {selectedOrgName}
            </Text>
            <Icon name="down-arrow" size="8" color="grey400" />
          </Stack>
        </Stack>
      </SelectButtonContainer>
      {isOpen && (
        <Container role="dialog" ref={orgSelectRef}>
          <StyledFormContainer>
            <form autoComplete="off" onSubmit={onFormSubmit}>
              <Box position="relative">
                <StyledInput
                  id="org-select-input"
                  name="org-select-input"
                  label="Organisation Search Filter"
                  placeholder="Search for an Organisation"
                  aria-label="Organisation Search Filter"
                  value={inputValue}
                  onChange={e => {
                    const value = e.target.value
                    setInputValue(value)
                    if (value.length >= MIN_QUERY_LENGTH) {
                      setSearchInProgress(true)
                      debouncedGetOrganisations(value)
                    }
                  }}
                  onKeyDown={onKeyDownHandler}
                  onBlur={() => {}}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  hideLabel
                />
                <StyledIcon name="search" size="12" aria-hidden />
                {inputValue.length > 0 && (
                  <StyledButton
                    type="button"
                    variant="tertiary"
                    color="darkGrey400"
                    icon="cross"
                    iconSize="8"
                    onClick={() => {
                      setInputValue('')
                      // Focus the input after clearing the filter text
                      document.getElementById('org-select-input')?.focus()
                    }}
                    aria-label="Reset Organisation Search Filter"
                    halo
                  />
                )}
              </Box>
            </form>
          </StyledFormContainer>
          {searchLoading ? (
            <Loader />
          ) : searchCalled && organisations.length === 0 ? (
            <EmptyContainer>
              <Text>No results found</Text>
            </EmptyContainer>
          ) : (
            <Box
              as="ul"
              maxHeight="100%"
              overflowY="auto"
              listStyleType="none"
              data-testid="select-menu"
            >
              {organisations.map((item, index) => (
                <MenuItem
                  key={item.value}
                  $isActive={index === activeItemIndex}
                  onClick={() => {
                    onChange(item.value)
                    toggleMenu()
                  }}
                >
                  {item.name || item.value}
                </MenuItem>
              ))}
            </Box>
          )}
          {inputValue.length < MIN_QUERY_LENGTH &&
            data &&
            data.organisationsPaginated.meta.totalPages > 1 && (
              <Text
                size="14"
                fontStyle="italic"
                color="darkGrey400"
                paddingLeft="xl"
                marginTop="m"
              >
                Start typing to search for more organisations
              </Text>
            )}
        </Container>
      )}
    </OrganisationSelectContainer>
  )
}
