import AsyncRemove from '@gameonsports/components/lib/AsyncRemove'
import Icon from '@gameonsports/components/lib/Icon'
import React, { Ref, forwardRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import useToggle from '../../hooks/useToggle'

type NotificationVariant = 'success' | 'error' | 'warning' | 'info' | 'status'

export interface NotificationProps {
  variant?: NotificationVariant
  fadeOutDelay?: number
  children: React.ReactNode
  className?: string
  id?: string
  hasCloseButton?: boolean
  onClose?: () => void
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeInAndOut = keyframes`
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const Container = styled.div<NotificationProps>`
  display: flex;
  position: relative;
  align-items: center;
  height: fit-content;
  padding: ${props =>
    props.hasCloseButton ||
    props.variant === 'success' ||
    props.variant === 'error'
      ? '1rem 3rem 1rem 1rem'
      : '1rem'};
  font-size: 0.875rem;
  line-height: 1.125;
  color: ${props => props.theme.black400};
  background-color: ${props =>
    props.variant === 'success'
      ? props.theme.successBackground
      : props.variant === 'warning'
      ? props.theme.warningBackground
      : props.variant === 'info'
      ? props.theme.infoBackground
      : props.variant === 'error'
      ? props.theme.errorBackground
      : props.theme.emptyBackground};
  animation: ${props =>
    props.fadeOutDelay
      ? css`
          ${fadeInAndOut} ${props.fadeOutDelay}ms ease
        `
      : css`
          ${fadeIn} 500ms ease
        `};
  ${props =>
    props.variant === 'warning' &&
    css`
      border: 1px solid ${props.theme.warningHighlight};
    `}

  ${props =>
    props.variant === 'status' &&
    css`
      border: 1px solid ${props.theme.secondary};
      font-style: italic;
    `}

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li:not(:last-of-type) {
    padding-bottom: 0.5rem;
  }

  a {
    color: inherit;
  }
`

const IconCircle = styled.div<NotificationProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    props.variant === 'success'
      ? props.theme.successHighlight
      : props.variant === 'error'
      ? props.theme.errorHighlight
      : props.variant === 'warning'
      ? props.theme.warningHighlight
      : props.variant === 'info'
      ? props.theme.infoHighlight
      : props.theme.secondary};
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
`

const StyledIcon = styled(Icon)`
  fill: white;
`

const WarnIcon = styled(Icon)`
  fill: ${props => props.theme.error};
  margin-right: 0.5rem;
`

const CloseButtonContainer = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
`

const CloseButton = styled.span`
  display: flex;
  cursor: pointer;
`

const NotificationBody = forwardRef(
  (props: NotificationProps, ref: Ref<HTMLDivElement>) => {
    const [on, toggle] = useToggle(true)

    if (!on) {
      return null
    }

    const handleClose = () => {
      if (props.onClose) {
        props.onClose()
      }
      toggle()
    }

    return (
      <Container variant={props.variant} {...props} ref={ref}>
        {(props.variant === 'success' || props.variant === 'error') && (
          <IconCircle variant={props.variant} data-testid="notification-icon">
            <StyledIcon
              name={props.variant === 'success' ? 'tick' : 'cross'}
              size="6"
            />
          </IconCircle>
        )}
        {props.variant === 'warning' && <WarnIcon name="warning" />}
        {props.variant === 'info' && (
          <IconCircle variant={props.variant} data-testid="notification-icon">
            <StyledIcon name="information" size="8" />
          </IconCircle>
        )}
        {props.children}
        {(props.hasCloseButton ||
          props.variant === 'success' ||
          props.variant === 'error') && (
          <CloseButtonContainer>
            <CloseButton onClick={handleClose} data-testid="close-button">
              <Icon
                name="cross"
                color={
                  props.variant === 'success'
                    ? 'successHighlight'
                    : props.variant === 'error'
                    ? 'errorHighlight'
                    : props.variant === 'warning'
                    ? 'warningHighlight'
                    : props.variant === 'info'
                    ? 'infoHighlight'
                    : 'secondary'
                }
                size="16"
              />
            </CloseButton>
          </CloseButtonContainer>
        )}
      </Container>
    )
  },
)

const Notification = forwardRef(
  (props: NotificationProps, ref: Ref<HTMLDivElement>) => {
    return props.fadeOutDelay ? (
      <AsyncRemove delay={props.fadeOutDelay}>
        <NotificationBody {...props} ref={ref} />
      </AsyncRemove>
    ) : (
      <NotificationBody {...props} ref={ref} />
    )
  },
)

Notification.defaultProps = {
  variant: 'success',
}

export default Notification
