import { OrganisationType } from '../generated/graphql'
import useOrg from './useOrg'

type OrgType = 'TENANT_ORGANISATION' | OrganisationType

const useOrgType = (orgType: OrgType): boolean | undefined => {
  const org = useOrg()

  return (
    org &&
    (org.type === orgType ||
      !!(org.isTenantOrganisation && orgType === 'TENANT_ORGANISATION'))
  )
}

export default useOrgType
